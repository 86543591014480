import Page from "../Page";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Item from "./Item";
import { Container } from "@mui/material";
import MetaTags from 'react-meta-tags';
import MetaTitle from "../../components/MetaTitle";
import CanonicalURL from "../../components/CanonicalURL";


const links = [
	{
		caption: 'WhatsApp',
		icon: WhatsAppIcon,
		url: 'https://wa.me/263715919598',
		iconColor: '#25d366',
	},
	{
		caption: 'Email',
		icon: EmailIcon,
		url: 'mailto:info@xavisoft.co.zw',
		iconColor: 'grey',
	},
	{
		caption: 'Phone',
		icon: PhoneIcon,
		url: 'tel:+263715919598',
		iconColor: '#007bff',
	},
	{
		caption: 'Facebook',
		icon: FacebookIcon,
		url: 'https://www.facebook.com/XavisoftDigital',
		iconColor: '#4267B2',
	},
	{
		caption: 'LinkedIn',
		icon: LinkedInIcon,
		url: 'https://www.linkedin.com/company/xavisoft-digital',
		iconColor: '#0077b5',
	},
]

export default class Contact extends Page {

	_render() {
		return <div>

			<MetaTags>
            <MetaTitle>Contact Us</MetaTitle>
            <meta name="description" content="Have any questions? Get in touch with us on our socials, sent us an email, or call us." />
				<CanonicalURL />
          </MetaTags>

			<Container>

				<h1 className='text-4xl font-bold text-center text-gray-700 py-[80px]'>Contact Us</h1>

				<div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 justify-items-center'>
					{links.map((link, index) => <Item key={index} {...link} />)}
				</div>
			</Container>

		</div>
	}
}