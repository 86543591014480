import { Container } from "@mui/material";
import Page from "../Page";
import Plan from "./Plan";
import { MetaTags } from "react-meta-tags";
import MetaTitle from "../../components/MetaTitle";
import CanonicalURL from "../../components/CanonicalURL";


const plans = [
	{
		name: "Core",
		price: 49.99,
		features: [
			{
				name: "School Administration",
				details: [
					"Manage staff, students, and fees",
					"Create and manage class schedules",
				]
			},
			{
				name: "Student and Parent Access",
				details: [
					"View student performance, homework, and timetable",
					"Monitor student fees and attendance",
				]
			},
			{
				name: "Teacher Tools",
				details: [
					"Manage classes and students",
					"Track student progress and performance",
				]
			},
		],
	},
	{
		name: "Connect",
		price: 59.99,
		features: [
			{
				name: "Everything in Core",
			},
			{
				name: "Integration with your existing accounting software",
				details: [
					"Sync your fees payments with your accounting software",
					"For example, QuickBooks, Xero, etc.",
				]
			},
		],
	},
	{
		name: "Complete",
		comingSoon: true,
		features: [
			{
				name: "Everything in Core",
			},
			{
				name: "Accounting feature",
				details: [
					"Profit and loss statement",
					"Accounts receivable",
					"Cash flow statement",
					"... etc.",
				]
			},
		],
	},
]


export default class Pricing extends Page {

	_render() {
		return <>
			<MetaTags>
            <MetaTitle>Pricing</MetaTitle>
            <meta name="description" content="Checkout our subscription plans" />
				<CanonicalURL />
          </MetaTags>

			<Container className="pb-16 lg:pb-28">
				<div className="text-center py-16 lg:py-28">
					<h1 className="text-2xl sm:text-5xl text-gray-700 font-extrabold">
						Check out the plans we offer
					</h1>
					<p className="text-sm sm:text-2xl text-gray-600 mt-1 sm:mt-2">
						Select the one that best suits your needs
					</p>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
					{plans.map((plan, i) => {
						return <Plan key={i} {...plan} />
					})}
				</div>
			</Container>
		</>
	}
}