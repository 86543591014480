import { Component } from "react";
import { connect } from "react-redux"

class FooterUnconnected extends Component {

	setDimensions = () => {
      const navbar = document.querySelector('footer');
      
      const width = navbar.scrollWidth + 'px';
      const height = navbar.scrollHeight + 'px';

      document.documentElement.style.setProperty('--footer-width', width);
      document.documentElement.style.setProperty('--footer-height', height);
   }

   componentWillUnmount() {
      this.resizeOberver.disconnect();
   }

   componentDidMount() {
      const resizeOberver = new window.ResizeObserver(this.setDimensions);
      resizeOberver.observe(document.querySelector('footer'));
      this.resizeOberver = resizeOberver;

      this.setDimensions();
   }

	render() {

		const { props } = this;

		if (props.isPWA || props.currentRoute?.startsWith('/dashboard'))
			return <footer />

		return <footer>
			<div className='bg-gradient-to-br from-white to-[#00F2]'>
				<div className='py-4'>
					<div className='flex justify-center items-center'>
						<div className='text-sm text-gray-500 font-bold'>
							© {new Date().getFullYear()} <a href='//xavisoft.co.zw' className='text-blue-500 inline-block hover:scale-[1.03]'>Xavisoft Digital</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	}
}


const mapStateToProps = state => {
	return {
		isPWA: state.isPWA,
		currentRoute: state.currentRoute,
	}
}

const Footer = connect(mapStateToProps)(FooterUnconnected);
export default Footer;