import { IconButton } from "@mui/material";
import React from "react";


/**
 * 
 * @param {object} props
 * @param {function} props.onClick
 * @param {"small"|"medium"|"large"|undefined} props.size
 * @param {"primary"|"secondary"|"tertiary"|undefined} props.color
 * @param {React.JSX.Element} props.children
 * @param {string|undefined} props.className
 * @param {boolean} props.disabled
 * @returns 
 */
export default function ClassmateIconButton(props) {

	const classes = [ 'rounded-lg' ];

	if (props.className)
		classes.push(props.className);
	
	switch (props.color) {
		case "primary":
			classes.push("text-white bg-[var(--primary)]")
			break;

		case "secondary":
			classes.push("bg-blue-50 text-[var(--primary)]");
			break;
	
		default:
			classes.push("bg-gray-50 text-gray-600");
			break;
	}

	return <IconButton style={props.style} onClick={props.onClick} size={props.size} className={classes.join(' ')} disabled={props.disabled}>
		{props.children}
	</IconButton>
}