import { SET_DEVICE } from "./constants";


const defaultDevice = {
	width: 1,
	height: 1,
	size: 'xs',
	orientation: 'portrait',
}

export default function deviceReducer(state = defaultDevice, action) {
	switch (action.type) {
		case SET_DEVICE:
			return action.payload;
		default:
			return state;
	}
}