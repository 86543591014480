import { Button, Dialog, DialogContent } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import actions from "../redux/actions";
import firebase from "../firebase";
import LaterIcon from '@mui/icons-material/WatchLater';
import SubscribeIcon from '@mui/icons-material/Notifications';

class PushNotificationsOptinDialogUnconnected extends Component {

	close = () => {
		actions.setDisplayPushNotificationsOptinDialog(false);
	}

	registerForNotifications = () => {
		const { ignoreRegistrationStatus } = this.props;
		firebase.registerForNotifications({ ignoreRegistrationStatus });
		this.close();
	}

	render() {

		let subscribeIcon, laterIcon;

		if (this.props.device.size !== 'xs') {
			subscribeIcon = <SubscribeIcon />
			laterIcon = <LaterIcon />
		}

		return <Dialog open={this.props.open}>
			<DialogContent>
				<div className="w-[400px] max-w-full text-gray-600 p-3 text-center">
					<div className="text-4xl font-bold">Push Notifications</div>
					<div className="text-lg mt-2">
						Allow us to send you important<br/>
						updates via notifications.
					</div>

					<div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-8">
						<Button fullWidth size="large" variant="contained" onClick={this.registerForNotifications} startIcon={subscribeIcon}>
							NOTIFY ME
						</Button>
						<Button fullWidth size="large" variant="outlined" onClick={this.close} startIcon={laterIcon}>
							MAYBE LATER
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	}
}

const mapStateToProps = state => ({
	open: state.displayPushNotificationsOptinDialog,
	ignoreRegistrationStatus: state.ignorePushNotificationsRegistrationStatus,
	device: state.device
})

const PushNotificationsOptinDialog = connect(mapStateToProps)(PushNotificationsOptinDialogUnconnected);
export default PushNotificationsOptinDialog;

