import { Component } from "react";
import TabPanel from "./TabPanel";
import TabPanelPagination from "./TabPanelPagination";
import { Chip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import LessonPlanEditor from "./LessonPlanEditor";
import EmptyState from "../../../../../components/EmptyState";
import ClassmateIconButton from "../../../../../components/ClassmateIconButton";


export default class LessonPlans extends Component {

	state = {
		editorMode: null,
		editorData: null,
	}

	openEditor = () => {
		this.setState({ editorMode: LessonPlanEditor.MODES.ADD });
	}

	render() {

		let body;
		const { lessonPlans } = this.props;

		if (lessonPlans) {		
			if (lessonPlans.length === 0) {
				body = <div className="h-[500px]">
					<EmptyState
						title="No lesson plans"
						subtitle="No lesson plans found"
						onAddButtonClicked={this.openEditor}
					/>
				</div>
			} else {
				body = <div>
					{lessonPlans.map(lessonPlan => {
						return <Chip
							key={lessonPlan._id}
							label={`Week ${lessonPlan.week} — Day ${lessonPlan.day}`}
							color="secondary"
							className="m-1 p-1 cursor-pointer"
							onClick={() => this.setState({ editorMode: LessonPlanEditor.MODES.VIEW, editorData: lessonPlan })}
						/>
					})}
				</div>
			}
		}

		const footer = <div className="grid grid-cols-[1fr,auto]">
			<TabPanelPagination
				page={this.props.lessonPlansPage}
				pageSize={this.props.pageSize}
				itemCount={this.props.count}
			/>
			<div className="flex items-center">
				<ClassmateIconButton
					size="small"
					color="primary"
					onClick={this.openEditor}
				>
					<AddIcon />
				</ClassmateIconButton>
			</div>
		</div>

		return <>
			<TabPanel
				heading="Lesson Plans"
				onRefresh={this.props.onRefresh}
				loading={this.props.loading}
				body={body}
				loaded={!!this.props.lessonPlans}
				footer={footer}
			/>
			{
				this.state.editorMode && <LessonPlanEditor
					close={() => {
						this.setState({ editorMode: null });

						if (this.state.editorMode === LessonPlanEditor.MODES.ADD) {
							this.props.onRefresh();
						}
					}}
					mode={this.state.editorMode}
					data={this.state.editorData}
					classSubjectId={this.props.classSubjectId}
					onDelete={this.props.onDelete}
				/>
			}
		</>
	}
}