
import PhoneIcon from '@mui/icons-material/Phone';
import EmaiIcon from '@mui/icons-material/AlternateEmail';
import ClassIcon from '@mui/icons-material/People';
import FeesIcon from '@mui/icons-material/Paid';
import Email from '../../components/Email';
import Phone from '../../components/Phone';

function Name({ name }) {
	return <div className="text-lg font-bold">
		{name}
	</div>
}

function IconLabeledData({ icon, data }) {

	const Icon = icon;

	return <div className='grid grid-cols-[auto,1fr] gap-2 [&>*]:flex [&>*]:items-center'>
		<div className='text-gray-500'>
			<Icon />
		</div>
		<div className='text-sm text-gray-600'>
			{data}
		</div>
	</div>
}

function renderEmail(email) {
	return email ?
		<Email email={email} /> : "(no email)"
}


function renderPhone(phone) {
	return phone ?
		<Phone phone={phone} /> : "(no phone number)"
}

export default function StudentAbout({ student }) {

	const { school, class:cls, outstanding_fees, user, student_no } = student;
	const { name, surname, email, phone } = user;

	return <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 [&>*]:border-[1px] [&>*]:border-gray-200 [&>*]:p-4 [&>*]:rounded">
		<div>
			<Name name={school.name} />
			<div className="text-gray-500 italic">
				{school.address}
			</div>
			<div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
				<IconLabeledData
					icon={PhoneIcon}
					data={renderPhone(school.phone)}
				/>
				<IconLabeledData
					icon={EmaiIcon}
					data={renderEmail(school.email)}
				/>
			</div>
		</div>
		<div>
			<div>
				<Name name={`${name} ${surname}`} />

				<div className="text-gray-500">
					{student_no}
				</div>

				<div className='mt-4 grid grid-cols-1 sm:grid-cols-2 gap-2'>

					{
						email && <IconLabeledData
							icon={EmaiIcon}
							data={<Email email={email} />}
						/>
					}
					{
						phone && <IconLabeledData
							icon={PhoneIcon}
							data={<Phone phone={phone} />}
						/>
					}

					<IconLabeledData
						icon={ClassIcon}
						data={cls.name}
					/>

					<IconLabeledData
						icon={FeesIcon}
						data={
							outstanding_fees > 0 ?
								<span className="text-red-600">
									<b>{outstanding_fees.toFixed(2)}</b> outstanding
								</span> : <span className="text-green-600 font-semibold">
									Paid
								</span>
						}
					/>

				</div>
			</div>
		</div>
</div>
}