
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

function OtherLinksUnconnected(props) {
   
   const links = [
      {
         caption: 'Home',
         to: '/'
      },
      {
         caption: 'Demo',
         to: '/demo'
      },
      {
         caption: 'Pricing',
         to: '/pricing'
      },
      {
         caption: "Contact",
         to: '/contact'
      }
   ];

   let { closeMobileNav } = props;
   if (typeof closeMobileNav !== 'function')
      closeMobileNav = () => { }

   return <div className="md:flex">
      {
         links.map(link => {

            const active = props.currentRoute === link.to;
            const color = active ? 'text-[var(--primary)]' : 'text-gray-600';
            const activeClasses = active ? 'border-b-[var(--primary)] md:border-b-[3px]' : ''

            return <div>
               <Link to={link.to} className={`font-bold text-lg hover:scale-[1.05] inline-block py-[1px] ${color} ${activeClasses} mx-3 box-content`} onClick={closeMobileNav}>
                  {link.caption}
               </Link>
            </div>
         })
      }
   </div>
}

const mapStateToProps = state => {
   return {
      currentRoute: state.currentRoute
   }
}

const OtherLinks = connect(mapStateToProps)(OtherLinksUnconnected)
export default OtherLinks;