import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Component } from "react";
import { errorToast, successToast } from "../../toast";
import { hideLoading, showLoading } from "../../loading";
import swal from "sweetalert";
import request from "../../request";


export default class PasswordEditor extends Component {

	submit = async () => {

		// presence check
		const txtOldPassword = document.getElementById('txt-old-password');
		const txtNewPassword = document.getElementById('txt-new-password');
		const txtConfirm = document.getElementById('txt-confirm');

		const old_password = txtOldPassword.value;
		const new_password = txtNewPassword.value;
		const confirm = txtConfirm.value;

		if (!old_password) {
			errorToast('Provide your current password');
			return txtOldPassword.focus();
		}

		if (!new_password) {
			errorToast('Provide your new password');
			return txtNewPassword.focus();
		}

		if (!confirm) {
			errorToast('Confirm your new password');
			return txtConfirm.focus();
		}

		if (new_password !== confirm) {
			errorToast('Passwords not matching');
			txtNewPassword.value = '';
			txtConfirm.value = '';
			return txtNewPassword.focus();
		}

		// submit
		const data = { old_password, new_password };

		try {

			showLoading();

			await request.post('/api/auth/new-password', data);
			successToast('Password updated successfully');
			
			this.props.close();

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}

	}

	render() {
		return <Dialog open>
			<DialogTitle>Update password</DialogTitle>
			<DialogContent dividers>
				<div className="[&>*]:my-2 max-w-[300px]">
					<TextField
						id="txt-old-password"
						label="Current password"
						size="small"
						variant="outlined"
						fullWidth
						type="password"
					/>
					<TextField
						id="txt-new-password"
						label="New password"
						size="small"
						variant="outlined"
						fullWidth
						type="password"
					/>
					<TextField
						id="txt-confirm"
						label="Confirm password"
						size="small"
						variant="outlined"
						fullWidth
						type="password"
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.submit} size="small" variant="contained">
					SUBMIT
				</Button>
				<Button onClick={this.props.close} size="small">
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}