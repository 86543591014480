import TimetableComponent from '../../../Timetable';
import FullscreenDialog from "../../../../../components/FullscreenDialog";

export default function Timetable(props) {

	const { user: { name, surname }, _id } = props.student;

	return <FullscreenDialog
		title={`${name} ${surname}'s timetable`}
		body={<TimetableComponent studentId={_id} />}
		close={props.close}
	/>
}