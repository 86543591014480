
function renderCell(row, col) {
	const field = col.field || col;
	const value = row[field];

	if (col.render)
		return col.render(value, row);
	else
		return value
}

function getCaption(col) {
	return typeof col.caption === 'undefined' ? col : col.caption;
}

/**
 * @returns {boolean}
 */
function _isRightAligned(rightAlign, smallScreen) {
	if (!rightAlign)
		return false;

	if (rightAlign === true)
		return true;

	if (smallScreen)
		return rightAlign.onSmallerScreens || false;

	return rightAlign.onBiggerScreens || false;
}

function isColumnRightAligned(tableRightAlign,columnRightAlign, smallScreen=false) {
	return _isRightAligned(tableRightAlign, smallScreen) || _isRightAligned(columnRightAlign, smallScreen);	
}

export {
	getCaption,
	renderCell,
	isColumnRightAligned,
}