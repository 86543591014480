import { Component } from "react";
import { Sidebar as ProSideBar, Menu, MenuItem, SubMenu, } from "react-pro-sidebar";
import Logo from "../../components/Logo";
import { Divider } from "@mui/material";
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from '@mui/icons-material/Logout';
import UpdatePasswordIcon from '@mui/icons-material/Lock';
import EditProfileIcon from '@mui/icons-material/Edit';
import UserThumbnail from "./UserThumbnail";
import { hideLoading, showLoading } from "../../loading";
import swal from "sweetalert";
import logout from "../../logout";


export default class Sidebar extends Component {

   renderIcon(Icon) {
      return <Icon
         className="text-xl"
      />
   }

   renderCaption(caption)  {
      return <span className="text-[13px]">
         {caption}
      </span>
   }

   renderExpandIcon({ open }) {
      const Icon = open ? ExpandMore : ChevronRight;
      return <Icon className="text-gray-400" />
   }

   onChange = (value) => {
      this.props.onChange(value);
   }

   renderMenuItems(items, current) {

      return items.map((item, index) => {

         if (item.children) {
            return <SubMenu 
               key={index}
               label={this.renderCaption(item.caption)}
               icon={this.renderIcon(item.icon)}
            >
               {this.renderMenuItems(item.children, current)}
            </SubMenu>
         } else {

            const className = current === item.value ? "bg-[var(--primary)] text-white shadow" : "";

            return <MenuItem 
               key={index} 
               onClick={() => this.onChange(item.value)}
               icon={this.renderIcon(item.icon)}
               className={`${className} hover:bg-blue-100 hover:text-[var(--primary)] hover:shadow`}
            >
               {this.renderCaption(item.caption)}
            </MenuItem>
         }
      });
   }

   logout = async () => {

      try {
         showLoading();
         await logout();
      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   render() {

      const current = this.props.active;

      const accountOperations = [
         {
            icon: EditProfileIcon,
            caption: 'Edit Profile',
            onClick: this.props.openProfileEditor,
         },
         {
            icon: UpdatePasswordIcon,
            caption: 'Update password',
            onClick: this.props.openPasswordEditor,
         }
      ]

      return <div className="h-full w-full bg-white relative">
         <ProSideBar backgroundColor="#1972D211" className="h-full w-full" >

            <div className="my-6 flex justify-center">
               <Logo _textClass="text-white" />
            </div>

            <Divider />

            <Menu
               menuItemStyles={{
                  button: {
                     '&:hover': {
                        backgroundColor: '#0000',
                     }
                  }
               }}
               renderExpandIcon={this.renderExpandIcon}
               className="text-gray-500"
            >
               {this.renderMenuItems(this.props.items, current)}
            </Menu>

         </ProSideBar>

         <div className="absolute bottom-0 w-full py-6 [&>*]:px-6 z-30">
            <div className="md:hidden">
               <UserThumbnail />
               <Divider className="mt-4 my-2" />
            </div>
            {
               accountOperations.map(
                  item => <div className="py-2 cursor-pointer hover:bg-gray-200 grid grid-cols-[auto,1fr] gap-4" onClick={item.onClick}>
                     <div className="flex items-center">
                        <item.icon className="text-gray-400" /> 
                     </div>
                     <div className="flex items-center text-gray-600 text-sm">
                        {item.caption}
                     </div>
                  </div>
               )
            }

            <Divider className="my-4" />

            <div className="py-2 cursor-pointer hover:bg-gray-200 grid grid-cols-[auto,1fr] gap-4" onClick={this.logout}>
               <div className="flex items-center">
                  <LogoutIcon className="text-gray-500" /> 
               </div>
               <div className="flex items-center text-gray-600 font-bold" >
                  Logout
               </div>
            </div>
         </div>
      </div>
   }
}
