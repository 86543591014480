import { Component } from "react";
import CenteredMessage from "../../../../components/CenteredMessage";
import request from "../../../../request";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../../loading";
import ChildThumbnail from "./ChildThumbnail";
import Child from "./Child";
import EmptyState from "../../../../components/EmptyState";
import PillLabel from "../../../../components/PillLabel";


export default class Children extends Component {

	state = {
		children: [],
		childrenFetched: false,
		childBeingViewed: null,
	}

	fetchChildren = async () => {
		
		try {

			showLoading();

			const res = await request.get('/api/parent/children');
			const { children }= res.data;

			this.setState({ children, childrenFetched: true });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	viewChild = (child) => {
		this.setState({ childBeingViewed: child });
	}

	componentDidMount() {
		this.fetchChildren()
	}

	render() {

		let jsx;

		if (!this.state.childrenFetched) {
			jsx = <CenteredMessage
				message={"Children not fetched"}
				onRefresh={this.fetchChildren}
			/>
		} else if (this.state.children.length === 0) {
			// this shouldn't happen, but just in case
			jsx = <EmptyState
				title={"No children found"}
			/>
		} else {
			jsx = <div>

				<PillLabel>
					CHILDREN
				</PillLabel>

				{this.state.children.map(child => {
					return <div className="mt-4">
						<ChildThumbnail
							{...child}
							onClick={() => this.viewChild(child) }
						/>
					</div>
				})}
			</div>
		}

		return (
			<div className="h-full p-2">
				{jsx}
				{
					this.state.childBeingViewed && <Child
						{...this.state.childBeingViewed}
						close={() => this.setState({ childBeingViewed: null })}
					/>
				}
			</div>
		)
	}
}