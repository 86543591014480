import { GENDER } from "../../../shared/constants";
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import { mdiGenderNonBinary } from '@mdi/js';
import SvgIcon from '@mui/material/SvgIcon';


export default function Gender({ gender, fullWidth=false }) {
	let icon;

	switch (gender) {
		case GENDER.MALE:
			icon = <MaleIcon fontSize="inherit" />;
			break;
		
		case GENDER.FEMALE:
			icon = <FemaleIcon fontSize="inherit" />;
			break;
	
		default:
			icon = <SvgIcon fontSize="inherit">
				<path d={mdiGenderNonBinary} />
			</SvgIcon>;
	}

	return <div className={`rounded-full pl-[3px] pr-[10px] py-[1px] bg-[var(--secondary)] text-white text-[10px] inline-grid grid-cols-[auto,1fr] gap-1 [&>*]:flex [&>*]:items-center`}>
		<div className="inline-block mr-[5px] text-[20px] text-gray-300 pr-[5px] border-r-[1px] border-r-white" >
			{icon}
		</div>
		<span className="capitalize text-[10px] font-bold">
			{gender.charAt(0)}
		</span>
	</div>
}