
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'; 
import CenteredMessage from '../CenteredMessage';
import EmptyState from '../EmptyState';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getCaption, isColumnRightAligned, renderCell } from './utils';
import { connect } from 'react-redux';
import Card from './Card';
import ClassmateIconButton from '../ClassmateIconButton';


// constants
const FIT_CONTENT_CLASSES = 'whitespace-nowrap w-[1%]';

// helpers
function NotFetchedEmptyStateTableWrapper({ children }) {
	return <TableRow>
		<TableCell colSpan={1000}>
			<div className='h-[500px]'>
				{children}
			</div>
		</TableCell>
	</TableRow>
}

// component
/**
 * @typedef RightAlignObject
 * @property {boolean|undefined} onBiggerScreens
 * @property {boolean|undefined} onSmallerScreens
 */
/**
 * 
 * @param {object} param0 
 * @param {Array<{
 * 	field: string,
 * 	caption: string,
 * 	render: function|undefined,
 * 	fitContent: boolean | undefined
 * 	rightAlign:boolean | RightAlignObject | undefined
 * }>} param0.cols
 * @param {Array<object>} param0.rows
 * @param {boolean} param0.fullHeight
 * @param {boolean | RightAlignObject | undefined} param0.rightAlign
 * @param {object|undefined} param0.pagination
 * @param {JSX.Element|undefined} param0.actions
 * @param {JSX.Element|undefined} param0.emptyState
 * @param {JSX.Element|undefined} param0.notFetchedState
 * @param {function} param0.onRefresh
 * @param {function} param0.onAdd
 * @returns 
 */
function ClassmateTableUnconnected({
	cols,
	rows,
	fullHeight=false,
	rightAlign,
	pagination,
	actions,
	emptyState,
	notFetchedState,
	onRefresh,
	onAdd,
	smallScreenCard,
	device
}) {

	let tableBody;
	const isSmallScreen = [ 'xs', 'sm' ].includes(device.size);
	const isXS = device.size === 'xs';

	if (!rows) {

		tableBody = notFetchedState || <CenteredMessage
			message={"Data not fetched"}
			onRefresh={onRefresh}
		/>;

		if (!isSmallScreen) {
			tableBody = <NotFetchedEmptyStateTableWrapper>
				{tableBody}
			</NotFetchedEmptyStateTableWrapper>
		}

	} else if (rows.length === 0) {

		tableBody = emptyState || <EmptyState onAddButtonClicked={onAdd} />;

		if (!isSmallScreen) {
			tableBody = <NotFetchedEmptyStateTableWrapper>
				{tableBody}
			</NotFetchedEmptyStateTableWrapper>
		}
	} else {
		if (isSmallScreen) {
			const EffectiveCard = smallScreenCard || Card;
			tableBody = rows.map(row => <div className='mx-2 my-8'>
					<EffectiveCard
						cols={cols}
						row={row}
						rightAlign={rightAlign}
					/>
				</div>
			)
		} else {
			tableBody = rows.map(row => {
				return <TableRow hover>
					{
						cols.map(col => {
							const rightAligned = isColumnRightAligned(rightAlign, col.rightAlign, isSmallScreen);
							const fitContent = rightAligned || col.fitContent;

							return <TableCell align={rightAligned ? 'right' : undefined} className={fitContent ? FIT_CONTENT_CLASSES : ''}>
								{renderCell(row, col)}
							</TableCell>
						})
					}
				</TableRow>
			});
		}
	}

	const cssClasses = [ ];

	if (fullHeight)
		cssClasses.push('h-full');

	let paginationJSX;
	if (pagination) {

		let selectProps;
		
		if (isSmallScreen) {
			pagination.labelRowsPerPage = '';
			selectProps = { className: 'hidden' }
		}

		paginationJSX = <TablePagination
			showFirstButton={!isXS}
			showLastButton={!isXS}
			slotProps={{
				select: selectProps,
			}}
			className='border-none'
			{...pagination}
		/>
	}


	let body;

	if (isSmallScreen) {
		body = <div className='h-full overflow-auto'>
			{tableBody}
		</div>
	} else {
		body = <TableContainer>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{
							cols.map(col => {

								const rightAligned = isColumnRightAligned(rightAlign, col.rightAlign, isSmallScreen);
								const align = rightAligned ? 'right' : undefined;
								const fitContent = rightAligned || col.fitContent;

								return <TableCell align={align} className={fitContent ? FIT_CONTENT_CLASSES : ''}>
									{getCaption(col)}
								</TableCell>
							})
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableBody}
				</TableBody>
			</Table>
		</TableContainer>
	}


	return <div className={`${cssClasses.join(' ')} grid grid-rows-[1fr,auto] text-gray-600`}>
		{body}
		<div className='grid grid-cols-[1fr,auto,auto] gap-2 border-t-[1px] border-t-[#ccc] pt-2'>
			<div className='h-full flex items-center'>
				{paginationJSX}
			</div>
			<div>
				{actions}
			</div>
			<div className='h-full flex items-center'>
				{
					onRefresh && <ClassmateIconButton
						onClick={onRefresh}
						size='small'
						color='secondary'
					>
						<RefreshIcon />
					</ClassmateIconButton>
				}
				{	
					onAdd && <ClassmateIconButton
						onClick={onAdd}
						size='small'
						color='primary'
						className='ml-2'
					>
						<AddIcon />
					</ClassmateIconButton>
				}
			</div>
		</div>
	</div>
}

const mapStateToProps = state => ({ device: state.device });

const ClassmateTable = connect(mapStateToProps)(ClassmateTableUnconnected);
export default ClassmateTable;