import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


/**
 * 
 * @param {object} props
 * @param {Function} props.onClose
 * @returns 
 */
export default function DialogTitleWithCloseButton(props) {
   return <DialogTitle>
      <div className="grid grid-cols-[1fr,auto] gap-4">
         <div className="flex items-center">
            {props.children}
         </div>
         <div className="flex items-center">
            <IconButton onClick={() => props.onClose()}>
               <CloseIcon />
            </IconButton>
         </div>
      </div>
   </DialogTitle>
}