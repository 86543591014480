import { CircularProgress, Dialog, DialogContent, DialogActions } from "@mui/material";
import DialogTitleWithCloseButton from "../../../../components/DialogTitleWithCloseButton";
import { Component } from "react";
import request from "../../../../request";
import swal from "sweetalert";
import ClassmateIconButton from "../../../../components/ClassmateIconButton";
import CenteredMessage from "../../../../components/CenteredMessage";
import QRCode from "react-qr-code";
import RefreshIcon from '@mui/icons-material/Refresh';


export default class AttendanceDeviceTokensGenerator extends Component {
	
	state = {
		tokens: null,
		loading: false
	}

	generateTokens = async () => {

		try {

			this.setState({ loading: true });

			const res = await request.get("/api/admin/attendance-device-tokens");
			const tokens = res.data;

			this.setState({ tokens});

		} catch (err) {
			swal("Error", err.message, "error");
		} finally {
			this.setState({ loading: false });
		}

	}

	componentDidMount() {
		this.generateTokens();
	}

	render() {

		let dialogContents;
		const { loading, tokens } = this.state;

		if (loading) {
			dialogContents = <div className="flex items-center justify-center h-full">
				<CircularProgress />
			</div>
		} else if (tokens) {
			dialogContents = <div>
				<QRCode value={JSON.stringify(tokens)} className="w-full" />
				<p className="text-center text-xs text-gray-600 mt-4">
					Scan the QR code with your <b>Attendance App</b>
				</p>
			</div>
		} else {
			dialogContents = <CenteredMessage
				message="Failed to generate tokens"
				onRefresh={this.generateTokens}
			/>
		}

		return <Dialog open>
			<DialogTitleWithCloseButton onClose={this.props.onClose}>
				Attendance Device Tokens
			</DialogTitleWithCloseButton>

			<DialogContent dividers>
				<div className="aspect-square max-w-full w-[350px] flex items-center justify-center">
					{dialogContents}
				</div>
			</DialogContent>
			<DialogActions>
				<ClassmateIconButton onClick={this.generateTokens} size="small" disabled={loading}>
					<RefreshIcon />
				</ClassmateIconButton>
			</DialogActions>
		</Dialog>
	}
}