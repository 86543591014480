import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Actions() {
	return <div className="grid sm:inline-grid grid-cols-2 gap-2">
		<Button
			component={Link}
			to="/signup"
			variant="contained"
			size="large"
			fullWidth
			className="hover:scale-[1.05]"
		>
			GET STARTED
		</Button>
		<Button
			component={Link}
			to="/demo"
			variant="contained"
			className="text-[var(--primary)] bg-blue-100 hover:bg-blue-200 hover:scale-[1.05]"
			disableElevation
			size="large"
			fullWidth
		>
			DEMO ACCOUNT
		</Button>
	</div>
}