
const GENDER = {
	MALE: 'male',
	FEMALE: 'female',
	OTHER: 'other',
 };
 
const SCHOOL_TYPES = {
	PRIMARY: 'primary',
	HIGH_SCHOOL: 'high-school'
}

const USER_ROLES = {
	ADMIN: 'admin',
	TEACHER: 'teacher',
	PARENT: 'parent',
	STUDENT: 'student',
}

const ATTENDANCE_DEVICE_ROLE = 'attendance-device';

const CURRENCIES = {
   USD: 'USD',
   ZIG: 'ZIG',
}

const SCHOOL_SUBSCRIPTION_PLANS = {
   CORE: 'CORE',
   CONNECT: 'CONNECT',
   COMPLETE: 'COMPLETE',
}

const SCHOOL_SUBSCRIPTION_PLAN_PRICES = {
	[SCHOOL_SUBSCRIPTION_PLANS.CORE]: 49.99,
	[SCHOOL_SUBSCRIPTION_PLANS.CONNECT]: 59.99,
	[SCHOOL_SUBSCRIPTION_PLANS.COMPLETE]: 99.99,
}

const BILLING_CYCLES = {
   MONTHLY: 'MONTHLY',
   ANNUALLY: 'ANNUALLY',
}

const TIMETABLE_SLOT_TYPES = {
   SLOT: 'slot',
   BREAK: 'break',
}

const TERMS_PER_YEAR = 3;

module.exports = {
	ATTENDANCE_DEVICE_ROLE,
	BILLING_CYCLES,
	CURRENCIES,
	GENDER,
	SCHOOL_SUBSCRIPTION_PLAN_PRICES,
	SCHOOL_SUBSCRIPTION_PLANS,
	SCHOOL_TYPES,
	TERMS_PER_YEAR,
	TIMETABLE_SLOT_TYPES,
	USER_ROLES,
};