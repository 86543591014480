import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { errorToast, successToast } from "../../../toast";
import { createGenderSelectItems, isEmail } from "../../../utils";
import request from "../../../request";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../loading";
import PropTypes from 'prop-types';
import ClassPicker from "./ClassPicker";
import { connect } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';


const _AddTeacherOrStudent = (props) => {

	const [ gender, setGender ] = useState('');
	const [ isNew, setIsNew ] = useState(false);
	const [ selectedClass, setSelectedClass ] = useState(null);
	const { mode, addToList: addEntityToList } = props;

	const submit = async () => {
		
		// extract form data
		let data;

		if (isNew) {
			const txtName = document.getElementById('txt-name');
			const txtSurname = document.getElementById('txt-surname');
			const txtGender = document.getElementById('txt-gender');
			const txtEmail = document.getElementById('txt-email');
			const txtPhone = document.getElementById('txt-phone');
			const txtPassword = document.getElementById('txt-password');
			const txtConfirmPassword = document.getElementById('txt-confirm-password');

			const name = txtName.value;
			const surname = txtSurname.value;
			const email = txtEmail.value;
			const phone = txtPhone.value;
			const password = txtPassword.value;
			const confirmPassword = txtConfirmPassword.value;

			if (!name) {
				errorToast('Name is required');
				return txtName.focus();
			}

			if (!surname) {
				errorToast('Surname is required');
				return txtSurname.focus();
			}

			if (!gender) {
				errorToast("Select gender");
				return txtGender.focus();
			}

			if (!email && !phone) {
				errorToast('Email or phone number is required');
				return txtEmail.focus();
			}

			if (!password) {
				errorToast('Password is required');
				return txtPassword.focus();
			}

			if (!confirmPassword) {
				errorToast('Confirm password');
				return txtConfirmPassword.focus();
			}

			if (password !== confirmPassword) {
				errorToast('Passwords do not match');
				return txtConfirmPassword.focus();
			}

			data = {
				is_new: true,
				name,
				surname,
				gender,
				email,
				phone,
				password,
			}

		} else {
			const txtEmailOrPhone = document.getElementById('txt-email-or-phone');
			const emailOrPhone = txtEmailOrPhone.value;

			if (!emailOrPhone) {
				errorToast('Email or phone number is required');
				return txtEmailOrPhone.focus();
			}

			data = { is_new: false };

			if (isEmail(emailOrPhone)) {
				data.email = emailOrPhone;
			} else {
				data.phone = emailOrPhone;
			}
		}

		if (mode === MODES.STUDENT) {
			if (!selectedClass)
				return errorToast('Select class');
		}

		data.class = selectedClass?._id;

		// send request
		try {

			showLoading();

			const uriComponent = mode === MODES.STUDENT ? 'students' : 'teachers';
			const res = await request.post(`/api/admin/${uriComponent}`, data);
				
			await addEntityToList({
				...res.data,
				class: selectedClass || undefined,
			});
			
			successToast('Added');
			props.close();

		} catch (err) {
			swal(err.toString());
		} finally {
			hideLoading();
		}
		
	}

	let form;

	if (isNew) {
		form = (
			<div className="[&>*]:my-2">
				<div className="grid grid-cols-2 gap-4">
					<TextField
						id="txt-name"
						label="Name"
						variant="outlined"
						size="small"
						fullWidth
					/>

					<TextField
						id="txt-surname"
						label="Surname"
						variant="outlined"
						size="small"
						fullWidth
					/>
				</div>

				<TextField
					id="txt-gender"
					value={gender}
					onChange={e => setGender(e.target.value)}
					label="Gender"
					variant="outlined"
					size="small"
					fullWidth
					select
				>
					{createGenderSelectItems()}
				</TextField>

				<div className="grid grid-cols-2 gap-4">
					<TextField
						id="txt-email"
						label="Email"
						variant="outlined"
						size="small"
						fullWidth
					/>

					<TextField
						id="txt-phone"
						label="Phone number"
						variant="outlined"
						size="small"
						fullWidth
					/>
				</div>
				
				<div className="grid grid-cols-2 gap-4">
					<TextField
						id="txt-password"
						label="Password"
						variant="outlined"
						size="small"
						type="password"
						fullWidth
					/>

					<TextField
						id="txt-confirm-password"
						label="Confirm"
						variant="outlined"
						size="small"
						type="password"
						fullWidth
					/>
				</div>
			</div>
		);
	} else {
		form = (
			<TextField
				id="txt-email-or-phone"
				label="Email or phone number"
				variant="outlined"
				size="small"
				fullWidth
			/>
		);
	}

	return (
		<Dialog open fullScreen={props.device.size === 'xs'}>
			<DialogTitle className="relative">
				Add {mode === MODES.STUDENT ? 'Student' : 'Teacher' }

				<div className="absolute top-0 bottom-0 right-0 flex items-center pr-3">
					<IconButton onClick={props.close}>
						<CloseIcon />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent dividers>

				<div className="sm:w-[400px]">
					<div>
						<Switch
							checked={isNew}
							onChange={e => setIsNew(e.target.checked)}
						/>

						<span>User doesn't have an account</span>

					</div>

					<Divider className="my-4" />

					{form}

					<div className="my-2">
						<ClassPicker
							onSelect={setSelectedClass}
						/>
					</div>

				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" onClick={submit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
	
}

const MODES = {
	TEACHER: 'teacher',
	STUDENT: 'student',
}

_AddTeacherOrStudent.propTypes = {
	mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
	close: PropTypes.func.isRequired,
	addToList: PropTypes.func.isRequired
}

const mapStateToProps = state => ({ device: state.device });
const AddTeacherOrStudent = connect(mapStateToProps)(_AddTeacherOrStudent);

export {
	MODES
}

export default AddTeacherOrStudent;