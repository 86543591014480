
import { numericFormatter } from 'react-number-format';

export default function NumberCard(props) {

   const {
      text,
      number,
      bgClassname="bg-gradient-to-tr from-[var(--primary)] to-blue-300",
      icon: Icon
   } = props;
   
   return <div className={`rounded-xl [&>*]:py-4 [&>*]:px-6 ${bgClassname} grid grid-cols-[1fr,auto] shadow hover:scale-[1.06]`}>
      <div>
         <div className="text-3xl text-white font-bold">
            {numericFormatter(String(number), { thousandSeparator: ','})}
         </div>
         <div className="text-sm text-gray-300 uppercase mt-1">
            {text}
         </div>
      </div>
      {
         Icon && <div className="h-full flex items-center border-l-[1px] border-l-[#ccc]">
            <Icon className="text-white text-4xl" />
         </div>
      }
   </div>
}