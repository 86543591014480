import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { Component } from "react";
import { errorToast } from "../../toast";
import { parseEmailOrPhone } from "./utils";
import { hideLoading, showLoading } from "../../loading";
import request from "../../request";
import swal from "sweetalert";

export default class ResetPassword extends Component {

	state = {
		emailOrPhone: '',
	}

	txtEmailPhoneRef = React.createRef();

	submit = async () => {

		// presence check
		const { emailOrPhone } = this.state;

		if (!emailOrPhone) {
			errorToast('Provide you email or phone number');
			return this.txtEmailPhoneRef.current.querySelector('input').focus();
		}

		// submit
		const data = parseEmailOrPhone(emailOrPhone);

		try {
			showLoading();

			await request.post('/api/auth/password-reset', data);
			swal('Success', `A link to reset your password has been sent to ${data.email || data.phone}`);

			this.props.close();
			
		} catch(err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	async componentDidMount() {
		if (this.props.username) {
			this.setState({ emailOrPhone: this.props.username });
		}
	}

	render() {
		return <Dialog open>
			<DialogTitle>Reset password</DialogTitle>
			<DialogContent dividers>
				<TextField
					ref={this.txtEmailPhoneRef}
					value={this.state.emailOrPhone}
					onChange={e => this.setState({ emailOrPhone: e.target.value })}
					label="Email or phone"
					variant="outlined"
					size="small"
					fullWidth
					className="my-2"
				/>	
			</DialogContent>
			<DialogActions>
				<Button variant="contained" size="small" onClick={this.submit}>
					SUBMIT
				</Button>
				<Button size="small" onClick={this.props.close}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}