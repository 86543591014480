import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TabPanel from "./TabPanel";
import CenteredMessage from "../../../../../components/CenteredMessage";
import { connect } from "react-redux";

function StudentsUnconnected({ students, loading, error, onRefresh, onDismissError, device }) {

	let studentsJSX;
	const isSmallScreen = [ 'xs', 'sm' ].includes(device.size);

	if (students) {
		if (students.length > 0) {
			studentsJSX = <Table stickyHeader>
				<TableHead>
					<TableRow>
						{!isSmallScreen && <TableCell>STUDENT NO</TableCell>}
						<TableCell>NAME</TableCell>
						<TableCell>SURNAME</TableCell>
						<TableCell>GENDER</TableCell>
						{!isSmallScreen && <TableCell>EMAIL</TableCell>}
						{!isSmallScreen && <TableCell>PHONE</TableCell>}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						students.map(student => {
							return <TableRow>
								{
									!isSmallScreen && <TableCell>
										{student.student_no}
									</TableCell>
								}
								<TableCell>{student.user?.name}</TableCell>
								<TableCell>{student.user?.surname}</TableCell>
								<TableCell>{student.user?.gender}</TableCell>
								{
									!isSmallScreen && <TableCell>
										{student.user?.email}
									</TableCell>
								}
								{
									!isSmallScreen && <TableCell>
										{student.user?.phone}
									</TableCell>
								}
							</TableRow>
						})
					}
				</TableBody>
			</Table>
		} else {
			studentsJSX = <div className="h-[500px]">
				<CenteredMessage
					message={"No students in this class"}
					onRefresh={onRefresh}
				/>
			</div>
		}
	} else {
		studentsJSX = <div className="h-[500px]">
			<CenteredMessage
				message={"Students not loaded"}
				onRefresh={onRefresh}
			/>
		</div>
	}

	return <TabPanel
		heading="Students"
		body={studentsJSX}
		loading={loading}
		error={error}
		onRefresh={onRefresh}
		onDismissError={onDismissError}
		loaded={!!students}
	/>
	
}

const mapStateToProps = state => ({ device: state.device });
const Students = connect(mapStateToProps)(StudentsUnconnected);

export default Students;