import { Component } from "react";
import TeacherContext from "../TeacherContext";
import { hideLoading, showLoading } from "../../../../loading";
import swal from "sweetalert";
import CenteredMessage from "../../../../components/CenteredMessage";
import { IconButton } from "@mui/material";
import Subject from "./Subject";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClassIcon from '@mui/icons-material/Group';


export default class Subjects extends Component {

	static contextType = TeacherContext;

	state = {
		selectedClassSubject: null,
	}

	fetchClassSubjects = async () => {
		try {
			showLoading();
			await this.context.fetchClassSubjects();
		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	viewClassSubject = (classSubject) => {
		return this.updateState({ selectedClassSubject: classSubject });
	}

	closeSubjectViewer = () => {
		return this.updateState({ selectedClassSubject: null });
	}

	componentDidMount() {
		if (!this.context.classSubjects)
			this.fetchClassSubjects();
	}

	render() {

		let body;
		const { classSubjects } = this.context;

		if (!classSubjects) {
			body = <CenteredMessage
				message="Subjects not loaded"
				onRefresh={this.fetchClassSubjects}
			/>
		} else if (!classSubjects.length) {
			body = <CenteredMessage
				message="No subjects found"
				onRefresh={this.fetchClassSubjects}
			/>
		} else {
			body = <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4'>
				{classSubjects.map(subject => {

					return <div
						key={subject._id}
						className='rounded border-solid border-[1px] border-[#ccc] p-3 cursor-pointer'
						onClick={() => this.viewClassSubject(subject)}
					>
						<div className='text-lg text-gray-600 font-semibold truncate'>
							{subject.subject.name}
						</div>
						<div className="grid grid-cols-[auto,1fr] gap-2">
							<div className="flex items-center text-[var(--primary)]">
								<ClassIcon />
							</div>
							<div className='text-sm text-gray-500 flex items-center'>
								{subject.class.name}
							</div>
						</div>
					</div>
				})}
			</div>
		}
		
		return <div className="h-full grid grid-rows-[1fr,auto] p-2">
			<div>

				<div className="text-gray-600 text-2xl font-bold my-4">
					Class Subjects
				</div>

				{body}
			</div>
			<div className="text-right">
				<IconButton onClick={this.fetchClassSubjects}>
					<RefreshIcon />
				</IconButton>
			</div>
			{
				this.state.selectedClassSubject && <Subject
					{ ...this.state.selectedClassSubject }
					close={this.closeSubjectViewer}
				/>
			}
		</div>
	}
}