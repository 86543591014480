
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { connect } from 'react-redux';

function UserThumbnailUnconnected(props) {

	const { name, surname, school } = props;

	return <div className="grid grid-cols-[auto,1fr] gap-2">
		<div className='flex items-center text-3xl text-gray-400'>
			<AccountCircleIcon fontSize='inherit' />
		</div>
		<div className='flex items-center'>
			<div className="text-xs text-gray-600 capitalize">
				<div className="font-bold">
					{name} {surname}
				</div>
				<div>
					{school}
				</div>
			</div>
		</div>
	</div>
}


const mapStateToProps = state => {
	const { name, surname, school } = state.user || {}
	return { name, surname, school: school?.name }
}

const UserThumbnail = connect(mapStateToProps)(UserThumbnailUnconnected);
export default UserThumbnail;