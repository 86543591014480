import { Component } from "react";


export default class ContentEditable extends Component {

	state = {
		editing: false,
		content: '',
	}

	getValue = () => {
		return this.state.editing ? this.state.content : this.props.value;
	}

	componentDidUpdate(prevProps) {
		const prevValue = prevProps.value;
		const value = this.props.value;

		if (prevValue !== value) {
			if (!Number.isNaN(value) || !Number.isNaN(prevValue)) // NaN !== NaN
				this.setState({ content: value });
		}
	}

	componentDidMount() {
		if (this.props.value !== undefined && this.props.value !== this.state.content)
			this.setState({ content: this.props.value });
	}

	render() {
		return (
			<input
				className="content-editable w-full border-0 bg-transparent outline-none"
				type={this.props.type}
				onFocus={() => this.setState({ editing: true,  })}
				onBlur={
					() => {

						this.setState({ editing: false });

						if (typeof this.props.onBlur === 'function') {
							
							let value = this.state.content;

							if (this.props.type === 'number')
								value = parseFloat(value) || '';
							this.props.onBlur(value);
						}
					}
				}
				onInput={
					e => {
						const content = e.target.value;
						this.setState({ content });
					}
				}
				value={this.getValue()}
			/>
		);
	}
}
