import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import 'quill/dist/quill.snow.css';
import React, { Component } from "react";
import { createQuillInstance, delay, isQuillEditorEmpty, requestConfirmation } from "../../../../../utils";
import { errorToast } from "../../../../../toast";
import { hideLoading, showLoading } from "../../../../../loading";
import swal from "sweetalert";
import request from "../../../../../request";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Delta } from "quill/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FullscreenDialog from "../../../../../components/FullscreenDialog";

function integersFromTo(from, to) {
	const arr = [];
	for (let i = from; i <= to; i++)
		arr.push(i);
	return arr;
}

const weeks = integersFromTo(1, 20);
const days = integersFromTo(1, 7);

export default class LessonPlanEditor extends Component {

	static MODES = {
		ADD: 'add',
		EDIT: 'edit',
		VIEW: 'view'
	}

	editorRef = React.createRef();

	state = {
		week: 0,
		day: 0,
		mode: LessonPlanEditor.MODES.ADD,
	}

	isAddMode() {
		return this.state.mode === LessonPlanEditor.MODES.ADD;
	}

	isViewMode() {
		return this.state.mode === LessonPlanEditor.MODES.VIEW;
	}

	isEditMode() {
		return this.state.mode === LessonPlanEditor.MODES.EDIT;
	}

	submit = async () => {

		const txtWeek = document.getElementById('txt-week');
		const txtDay = document.getElementById('txt-day');

		const { week, day } = this.state;

		if (!week) {
			errorToast('Please select a week');
			return txtWeek.focus();
		}

		if (!day) {
			errorToast('Please select a day');
			return txtDay.focus();
		}

		if (isQuillEditorEmpty(this.quill)) {
			errorToast('No content');
			return this.editorRef.current.focus();
		}

		const content = this.quill.getContents();

		try {

			showLoading();

			const data = {
				week,
				day,
				content: JSON.stringify(content),
			}

			const classSubjectId = this.getClassSubjectId();
			let url = `/api/teacher/class-subjects/${classSubjectId}/lesson-plans`;
			let method;

			if (this.isAddMode()) {
				method = 'POST';
			} else {
				method = 'PATCH';
				url += `/${this.getLessonPlanId()}`;
			}

			await request({ method, url, data });
			this.props.close();

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	getClassSubjectId() {
		return this.props.classSubjectId;
	}

	getLessonPlanId() {
		return this.props.data._id;
	}

	edit = () => {
		this.quill.enable();
		this.setState({ mode: LessonPlanEditor.MODES.EDIT });
	}

	delete = async () => {

		const confirm = await requestConfirmation('Delete lesson plan?');
		if (!confirm)
			return;

		try {

			showLoading();

			const classSubjectId = this.getClassSubjectId();
			const lessonPlanId = this.getLessonPlanId();

			await request.delete(`/api/teacher/class-subjects/${classSubjectId}/lesson-plans/${lessonPlanId}`);
			await this.props.close();
			this.props.onDelete();

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}

	}

	initializeQuillEditor = async () => {

		while (!this.editorRef.current)
			await delay(100);

		if (this.quill)
			return;

		const quill = createQuillInstance(this.editorRef.current);

		if (this.isViewMode()) {
			quill.setContents(new Delta().insert('Content not loaded...'));
			quill.disable();
		}

		this.quill = quill;

	}

	fetchContent = async () => {

		try {

			showLoading();

			const classSubjectId = this.getClassSubjectId();
			const lessonPlanId = this.getLessonPlanId();
			const res = await request.get(`/api/teacher/class-subjects/${classSubjectId}/lesson-plans/${lessonPlanId}`);

			const { content } = res.data;
			this.quill.setContents(JSON.parse(content));

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	async componentDidMount() {

		await this.updateState({ mode: this.props.mode });
		this.initializeQuillEditor();

		if (!this.isAddMode()) {
			this.fetchContent();
			const { week, day } = this.props.data;
			this.setState({ week, day });
		}
	}

	render() {

		const body = <div className="[&>*]:my-4">
			<div className="grid grid-cols-2 gap-4">
				<TextField
					id="txt-week"
					label="Week"
					size="small"
					fullWidth
					select
					value={this.state.week}
					onChange={e => this.setState({ week: e.target.value })}
					disabled={this.isViewMode()}
				>
					{
						weeks.map(week => {
							return <MenuItem value={week} key={week}>{week}</MenuItem>
						})
					}
				</TextField>

				<TextField
					id="txt-day"
					label="Day"
					size="small"
					fullWidth
					select
					value={this.state.day}
					onChange={e => this.setState({ day: e.target.value })}
					disabled={this.isViewMode()}
				>
					{
						days.map(day => {
							return <MenuItem value={day} key={day}>{day}</MenuItem>
						})
					}
				</TextField>
			</div>
			<div>
				<div ref={this.editorRef} />
			</div>
		</div>

		const actions = <>
			{
				this.isViewMode() && <>
					<IconButton size="small" onClick={this.fetchContent}>
						<RefreshIcon />
					</IconButton>
					<IconButton variant="contained" size="small" onClick={this.edit}>
						<EditIcon />
					</IconButton>
					<IconButton variant="contained" size="small" onClick={this.delete} color="error">
						<DeleteIcon />
					</IconButton>
				</>
			}
			{
				!this.isViewMode() && <Button variant="contained" size="small" onClick={this.submit}>
					SUBMIT
				</Button>
			}
		</>

		return <FullscreenDialog
			title={"Add lesson plan"}
			body={body}
			actions={actions}
			close={this.props.close}
		/>
	}
}
