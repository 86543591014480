
import swal from 'sweetalert';
import withRouterParams from '../withRouterParams';
import Page from './Page';
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import MetaTitle from '../components/MetaTitle';
import { errorToast } from '../toast';


class ResetPasswordVanilla extends Page {

	state = {
		success: false,
	}

	reset = async () => {

		const txtPassword = document.getElementById('txt-password');
		const txtConfirm = document.getElementById('txt-confirm');

		const password = txtPassword.value;
		const confirm = txtConfirm.value;

		if (!password) {
			errorToast('Provide your password');
			return txtPassword.focus();
		}

		if (!confirm) {
			errorToast('Confirm your password');
			return txtConfirm.focus();
		}

		if (password !== confirm) {
			errorToast('Passwords not matching');
			txtPassword.value = '';
			txtConfirm.value = '';
			return txtPassword.focus();
		}

		try {

			showLoading();

			const { ref_code } = this.props.params;
			const data = { ref_code, password };
			await request.post('/api/auth/password-reset/verification', data);

			await this.updateState({ success: true });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	_render() {

		let btn, jsx;

		if (this.state.success) {
			jsx = <p className='text-xl text-gray-600'>
				You have successfully reset your password. Login with your new password.
			</p>

			btn = <Button variant='contained' size='small' className='mt-2' component={Link} to="/login">
				LOGIN
			</Button>

		} else {

			jsx = <div className='text-gray-600 [&>*]:my-2'>
				<div className='text-2xl font-bold'>
					New password
				</div>

				<TextField
					id='txt-password'
					label="New password"
					fullWidth
					variant='outlined'
					type='password'
					size='small'
				/>


				<TextField
					id='txt-confirm'
					label="Confirm password"
					fullWidth
					variant='outlined'
					type='password'
					size='small'
				/>
			</div>

			btn = <Button onClick={this.reset} variant='contained' size='large'>
				RESET
			</Button>
		}

		return <>
			<MetaTags>
				<MetaTitle>Reset Your Password</MetaTitle>
			</MetaTags>
			<div className='page-height flex justify-center items-center'>
				<div className='p-6 w-full mx-4 max-w-[400px] border-b-[grey] border-b-[1px]'>
					{jsx}
					<div className='mt-2'>
						{btn}
					</div>
				</div>
			</div>
		</>
	}
}

const ResetPassword = withRouterParams(ResetPasswordVanilla);
export default ResetPassword;