import Delete from "@mui/icons-material/Delete";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../../loading";
import request from "../../../../request";
import AddTeacherOrStudent, { MODES as AddTeacherOrStudentModes } from "../AddTeacherOrStudent";
import { requestConfirmation } from "../../../../utils";
import { successToast } from "../../../../toast";
import Edit from "@mui/icons-material/Edit";
import AssignClass from "./AssignClass";
import CenteredMessage from "../../../../components/CenteredMessage";
import EmptyState from "../../../../components/EmptyState";
import ClassmateTable from "../../../../components/ClassmateTable";
import Gender from "../../../../components/Gender";
import PillLabel from "../../../../components/PillLabel";
import Email from "../../../../components/Email";
import Phone from "../../../../components/Phone";
import { connect } from "react-redux";

class _Students extends Component {

   state = {
      students: null,
      studentCount: 0,
      page: 0,
      pageSize: 10,
      studentEditorOpen: false,
      assignClassModalOpen: false,
      selectedStudent: null,
      onlyWithOutstandingFees: false
   }

   openAssignClassModal = selectedStudent => {
      return this.updateState({ assignClassModalOpen: true, selectedStudent });
   }

   closeAssignClassModal = async selectedClass => {

      const updates = { assignClassModalOpen: false, selectedStudent: null };

      if (selectedClass) {
         updates.students = this.state.students.map(item => {
            if (item._id === this.state.selectedStudent._id)
               return { ...item, class: selectedClass };
            return item;
         });
      }

      return this.updateState(updates);

   }

   openStudentEditor = () => {
      return this.updateState({ studentEditorOpen: true });
   }

   closeStudentEditor = () => {
      return this.updateState({ studentEditorOpen: false });
   }

   fetchStudents = async () => {
      try {

         showLoading();

         await this.updateState({ students: null });

         const params = {
            limit: this.state.pageSize,
            page: this.state.page + 1,
         };

         if (this.state.onlyWithOutstandingFees)
            params.with_outstanding_fees = true;

         const res = await request.get(`/api/general/students`, { params });
         const students = res.data.students;
         const studentCount = res.data.count;

         await this.updateState({ students, studentCount });
         

      } catch (err) {
         swal(String(err))
      } finally {
         hideLoading();
      }
   }

   deleteStudent = async (student) => {

      // ask for confirmation
      const { name, surname } = student.user;
      const question = `Are you sure you want to delete student "${name} ${surname}"?`;
      const confirm = await requestConfirmation(question);

      if (!confirm)
         return;

      try {

         // delete from server
         showLoading();
         await request.delete(`/api/admin/students/${student._id}`);

         // delete from UI
         const students = this.state.students.filter(item => item._id !== student._id);
         await this.updateState({ students });

         successToast('Deleted');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   addStudent = (student) => {
      const students = [ ...this.state.students, student ];
      return this.updateState({ students });
   }

   componentDidMount() {
      this.fetchStudents();
   }

   render() {

      const isSmallScreen = [ 'xs', 'sm' ].includes(this.props.device.size);
      let actions;

      if (!isSmallScreen) {
         actions = <div className="h-full flex items-center">
            <FormControlLabel
               label="Only with outstanding fees"
               control={
                  <Checkbox
                     checked={this.state.onlyWithOutstandingFees}
                     onChange={async e => {
                        await this.updateState({
                           onlyWithOutstandingFees: e.target.checked,
                           page: 0
                        });

                        this.fetchStudents();
                     }}
                  />
               }
               />
         </div>
      }

      return <>
         <ClassmateTable
            cols={[
               {
                  caption: 'STUDENT NO',
                  field: 'student_no',
                  render: studentNo => <PillLabel>{studentNo}</PillLabel>
               }, 
               { caption: 'NAME', field: 'user', render: (user) => user.name  },
               { caption: 'SURNAME', field: 'user', render: (user) => user.surname  },
               {
                  caption: 'GENDER',
                  field: 'user',
                  render: (user) => <Gender gender={user.gender} />
               },
               {
                  caption: 'EMAIL',
                  field: 'user',
                  render: (user) => <Email email={user.email} />
               },
               {
                  caption: 'PHONE',
                  field: 'user',
                  render: (user) => <Phone phone={user.email} />
               },
               {
                  caption: 'CLASS',
                  field: 'class',
                  render: (cls, student) => {
                     if (cls) {
                        const { name } = cls;

                        return <div>
                           <IconButton onClick={() => this.openAssignClassModal(student) }>
                              <Edit className="text-xs" />
                           </IconButton>
                           <span>
                              {name}
                           </span>
                        </div>
                     } else {
                        return <span
                           className="inline-block bg-[var(--primary)] text-white text-[8px] py-[2px] px-[8px] shadow-lg rounded-full cursor-pointer"
                           onClick={() => this.openAssignClassModal(student)}
                        >
                           ASSIGN
                        </span>
                     }
                  }
               }, 
               {
                  caption: '',
                  field: 'actions',
                  render: (_, student) => {
                     return <IconButton color="error" onClick={() => this.deleteStudent(student) }>
                        <Delete />
                     </IconButton>
                  }
               }
            ]}
            rows={this.state.students}
            fullHeight
            pagination={{
               page: this.state.page,
               count: this.state.studentCount,
               labelRowsPerPage: "Students per page",
               rowsPerPage: this.state.pageSize,
               onPageChange: async (_, page) => {
                  await this.updateState({ page });
                  this.fetchStudents();
               },
               onRowsPerPageChange: async (event) => {
                  await this.updateState({ page: 0, pageSize: event.target.value });
                  this.fetchStudents();
               }
            }}
            actions={actions}
            onRefresh={this.fetchStudents}
            onAdd={this.openStudentEditor}
            notFetchedState={
               <CenteredMessage
                  message={"Students not fetched"}
                  onRefresh={this.fetchStudents}
               />
            }
            emptyState={
               <EmptyState
                  title="Empty list"
                  subtitle="No students returned"
                  addButtonCaption="ENROL"
                  onAddButtonClicked={this.openStudentEditor}
               />
            }
         />
        
         {
            this.state.studentEditorOpen && <AddTeacherOrStudent
               mode={AddTeacherOrStudentModes.STUDENT}
               close={this.closeStudentEditor}
               addToList={this.addStudent}
            />
         }

         {
            this.state.assignClassModalOpen && <AssignClass
               student={this.state.selectedStudent}
               close={this.closeAssignClassModal}
            />
         }
         
      </>
   }
}

const mapStateToProps = state => ({ device: state.device })
const Students = connect(mapStateToProps)(_Students);

export default Students;