
export default function onNotificationClick(event) {
   
   // eslint-disable-next-line no-restricted-globals
   const _self = self;
   const url = `${_self.location.origin}/login`;

   try {
      event.waitUntil(_self.clients.openWindow(url));
      event.notification.close();
   } catch (err) {
      _self.location = url;
      this.close();
   }
}