import { TextField } from "@mui/material";
import { Component } from "react";
import CenteredMessage from "../../../components/CenteredMessage";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenDialog from "../../../components/FullscreenDialog";


export default class SubjectConstraints extends Component {
	
	fetchClassSubjects = async () => {
		return this.props.fetchClassSubjects();
	}

	componentDidMount() {
		if (!this.props.subjectConstraints)
			this.fetchClassSubjects();
	}

	render() {

		let dialogContent;

		if (this.props.subjectConstraints) {

			if (this.props.subjectConstraints.length === 0) {
				dialogContent = <CenteredMessage message="No subjects found." />
			} else {
				dialogContent = this.props.subjectConstraints.map((subjectConstraint, i) => {

					const { level, subjects } = subjectConstraint;
					return <Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<span className="text-gray-600 font-bold text-lg">
								Level {level}
							</span>
						</AccordionSummary>
						<AccordionDetails>
							{
								subjects.map((subject, j) => {
									return <Accordion key={subject._id}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<span className="text-gray-600 font-bold text-sm">
												{subject.name}
											</span>
										</AccordionSummary>
										<AccordionDetails>
											<div className="grid grid-cols-1 sm:inline-grid sm:grid-cols-3 gap-4">
												<TextField
													label="Slots per week"
													size="small"
													type="number"
													value={subject.slots_per_week}
													onChange={e => this.props.updateSubjectConstraint(i, j, { slots_per_week: e.target.value }) }
													fullWidth
												/>
												<TextField
													label="Slots per session"
													size="small"
													type="number"
													value={subject.slots_per_session}
													onChange={e => this.props.updateSubjectConstraint(i, j, { slots_per_session: e.target.value }) }
													fullWidth
												/>
												<TextField
													label="Cuttoff slot"
													size="small"
													type="number"
													value={subject.cutoff_slot}
													onChange={e => this.props.updateSubjectConstraint(i, j, { cutoff_slot: e.target.value || undefined }) }
													title="The slot after which the subject should not be scheduled."
													helperText="Leave empty for no cutoff."
													fullWidth
												/>
											</div>
										</AccordionDetails>
									</Accordion>
								})
							}
						</AccordionDetails>
					</Accordion>
				})
			}
		} else {
			dialogContent = <CenteredMessage
				message="Data not yet fetched."
				onRefresh={this.fetchClassSubjects}
			/>
		}

		return <FullscreenDialog
			title={"Subject Constraints"}
			body={dialogContent}
			close={this.props.close}
		/>

	}

}