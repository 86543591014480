
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Component } from 'react';
import { errorToast } from '../../../toast';


export default class SlotEditor extends Component {

	state = {
		length: '',
		name: ''
	}

	save = () => {
		
		// check values
		const txtName = document.getElementById('txt-name');
		const txtBreakLength = document.getElementById('txt-break-length');

		const name = txtName?.value;
		const length = txtBreakLength?.value;


		if (!txtName.value) {
			errorToast('Name is required');
			return txtName.focus();
		}

		if (!length) {
			errorToast('Break length is required');
			return txtBreakLength.focus();
		}

		// save
		this.props.close({
			length: parseInt(length) || undefined,
			name: name || undefined,
		});

	}
	
	componentDidMount() {
		if (this.props.editMode) {
			const { length, name } = this.props.slot;
			this.setState({ length: length, name });
		}
	}

	render() {
		return <Dialog open>
			<DialogTitle>
				{this.props.editMode ? 'Edit' : 'Add' } Break
			</DialogTitle>

			<DialogContent dividers>
				<div className='max-w-[400px] py-1 grid grid-cols-1 sm:grid-cols-2 gap-4'>
					<TextField
						id='txt-name'
						value={this.state.name}
						onChange={e => this.setState({ name: e.target.value })}
						label="Name"
						variant='outlined'
						size='small'
						fullWidth
					/>
					<TextField
						id='txt-break-length'
						value={this.state.length}
						onChange={e => this.setState({ length: e.target.value })}
						label="Length (min)"
						variant='outlined'
						type="number"
						size='small'
						fullWidth
					/>
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" size="small" onClick={this.save}>
					SAVE
				</Button>
				<Button size="small" onClick={() => this.props.close()}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}