

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import { SCHOOL_TYPES } from '../../../../../../shared/constants';
import { errorToast, successToast } from '../../../../toast';
import { hideLoading, showLoading } from '../../../../loading';
import swal from 'sweetalert';
import request from '../../../../request';
import { returnChangedAttributes } from '../../../../utils';
import actions from '../../../../redux/actions';
import { getClassLevelLabel } from '../../../../../../shared/utils';


class ClassEditor extends React.Component {

	static MODES = {
		ADD: 'add',
		EDIT: 'edit'
	}

	state = {
		level: '',
	}

	submit = async () => {

		const txtName = document.getElementById('txt-name');
		const txtLevel = document.getElementById('txt-level');

		const name = txtName.value;
		const { level } = this.state;

		if (!name) {
			errorToast("Provide class name");
			return txtName.focus();
		}

		if (!level) {
			errorToast("Select class level");
			return txtLevel.focus();
		}

		let data = { name, level };
		let url = '/api/admin/classes';
		let method;
		const { mode } = this.props;

		if (mode === ClassEditor.MODES.EDIT) {

			data = returnChangedAttributes(data, this.props.class);

			if (Object.keys(data).length === 0)
				return errorToast('No changes made');

			url = `${url}/${this.props.class._id}`;
			method = 'patch';

		} else {
			method = 'post';
		}


		showLoading();

		try {

			const res = await request({
				method,
				url,
				data,
			});

			await this.props.close();

			if (mode === ClassEditor.MODES.EDIT) {
				actions.updateClass(this.props.class._id, data);
				successToast('Class edited');
			} else {
				const { _id } = res.data;
				actions.addClass({ ...data, _id });

				successToast("Class added");
			}

		} catch (err) {
			swal(err.toString());
		} finally {
			hideLoading();
		}
	}

	async componentDidMount() {
		if (this.props.mode === ClassEditor.MODES.EDIT) {
			const { level, name } = this.props.class;
			await this.updateState({ level });
			document.getElementById('txt-name').value = name;
		}
	}

	render() {

		const schoolType = this.props.schoolType;
		const levelCount = schoolType === SCHOOL_TYPES.HIGH_SCHOOL ? 6 : 7;
		const levelPrefix = getClassLevelLabel(schoolType);

		const levelMenuItems = [];

		for (let i = 1; i <= levelCount; i++) {
			levelMenuItems.push(
				<MenuItem value={i}>
					{levelPrefix} {i}
				</MenuItem>
			)
		} 

		return <Dialog open>
			<DialogTitle>{ this.props.mode === ClassEditor.MODES.EDIT ? 'Edit' : 'Add' } class</DialogTitle>

			<DialogContent dividers>
				<div className='md:w-[400px]'>
					<div className='grid grid-cols-2 gap-4'>
						<TextField
							id="txt-name"
							variant="outlined"
							label="Name"
							size='small'
							fullWidth
						/>

						<TextField
							id="txt-level"
							variant="outlined"
							label="Level"
							size='small'
							fullWidth
							select
							value={this.state.level}
							onChange={e => this.setState({ level: e.target.value })}
						>
							{levelMenuItems}
						</TextField>

					</div>
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					size='small'
					onClick={this.submit}
				>
					SAVE
				</Button>
				<Button
					size='small'
					onClick={this.props.close}
				>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}

export default ClassEditor;