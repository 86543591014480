import { SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS } from "./constants";


export default function ignorePushNotificationsRegistrationStatusReducer(state = false, action) {
	switch (action.type) {
		case SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS:
			return action.payload;
		default:
			return state;
	}
}