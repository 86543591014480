import { LOCAL_STORAGE_KEY } from "@xavisoft/auth/constants";
import firebase from "./firebase";
import request from "./request";
import actions from "./redux/actions";

export default async function logout() {

   // deregister push notifications
   const { token, } = firebase.getFCMLocalStorage();

   if (token) {
      const data = { push_notification_token: token }
      await request.delete('/api/auth/login', { data });
   }

   // remove jwt tokens
   localStorage.removeItem(LOCAL_STORAGE_KEY);

   // set auth status to false
   actions.setAuthenticated(false);

   // navigate to login
   // setting location instead of using window.App.redirect so that
   // data in contexts and redux store is destroyed
   // TODO: find a better way that this 👆
   window.location = '/login';
}