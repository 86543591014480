
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { delay } from "./utils";
import request from "./request";
import onNotificationClick from "./onNotificationClick";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAqzYg0rnNy-1zbYw-kveF7F5x22nA38YE",
	authDomain: "classmate-e5d1f.firebaseapp.com",
	projectId: "classmate-e5d1f",
	storageBucket: "classmate-e5d1f.appspot.com",
	messagingSenderId: "901895204666",
	appId: "1:901895204666:web:b1c4f9f9b328b936a8aa03",
	measurementId: "G-PH44H9EJ5D"
};

const FCM_STORAGE_KEY = 'fcm-c9a0781f-9d45-498e-b98d-be58edd44b8d';

const firebase = {
	init() {
		// Initialize Firebase
		const app = initializeApp(firebaseConfig);
		this._app = app;

		this.initializeMessaging();
	},

	getServiceWorkerRegistration() {
		return navigator.serviceWorker.ready;
	},

	async getToken() {
		const vapidKey = 'BPl_DfnibLFI3o4YxJK8rEVw5ffUDE_tOEhBpe2aD2xI6zyU0GAejvMH6MLBggtJKJBSFPhIH7PHpr21lR-UjYM';
		const serviceWorkerRegistration = await this.getServiceWorkerRegistration();
		const token = await getToken(this._messaging, { vapidKey, serviceWorkerRegistration });
		return token;
	},

	async initializeMessaging() {
		const messaging = getMessaging(this._app);
		this._messaging = messaging;

		onMessage(messaging, (payload) => {

			const notif = JSON.parse(payload.data.notification || null);

			if (notif) {

				const { title } = notif;

				const notification = new Notification(
					title,
					{
						icon: '/favicon.ico',
						...notif,
					}
				);

				notification.addEventListener("click", onNotificationClick);

			}

		});
	},

	async registerForNotifications({ ignoreRegistrationStatus=false }) {

		try {

			if (ignoreRegistrationStatus) {
				// makes sure registration is done
				// this is useful when auth expires and a different user logs in
				this._updateFCMLocalStorage({ registered: false });
			}
			
			const obj = this.getFCMLocalStorage();
			let { token, registered } = obj;

			const permission = await Notification.requestPermission();
			if (permission !== 'granted')
				return;

			const currentToken = await this.getToken();

			if (token !== currentToken) {
				// not registered or current registration now invalid
				registered = false;
				token = currentToken;
				this._updateFCMLocalStorage({ token, registered });
			}

			if (registered)
				return;

			await request.post('/api/auth/push-notification-tokens', { token })
			this._updateFCMLocalStorage({ registered: true });
			
		} catch (err) {
			await delay(10000);
			this.registerForNotifications();
		}
			
	},

	/**
	 * 
	 * @returns {{ token: string | undefined, registered: boolean | undefined}}
	 */
	getFCMLocalStorage() {
		const json = localStorage.getItem(FCM_STORAGE_KEY);
		return JSON.parse(json) || {}; 
	},

	_updateFCMLocalStorage(update={}) {
		let obj = this.getFCMLocalStorage();
		obj = { ...obj, ...update }
		const json = JSON.stringify(obj);
		localStorage.setItem(FCM_STORAGE_KEY, json);
	},

	deleteFCMLocalStorage() {
		localStorage.removeItem(FCM_STORAGE_KEY);
	}

}


export default firebase;