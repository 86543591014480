import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function FullscreenDialog({ title, body, actions, close }) {

	return <Dialog open fullScreen>

		<DialogTitle className="p-0">
			<div className="grid grid-cols-[auto,1fr]">
				<div className="h-full flex items-center border-r-gray-200 border-r-[1px] px-4">
					<IconButton onClick={() => close()} size="large">
						<ArrowBackIcon />
					</IconButton>
				</div>
				<div className="h-full flex items-center">
					<div className="w-full p-4">
						{title}
					</div>
				</div>
			</div>
		</DialogTitle>

		<DialogContent dividers>
			{body}
		</DialogContent>
		{
			actions && <DialogActions>
				{actions}
			</DialogActions>
		}
	</Dialog>
}