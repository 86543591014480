import { Button } from "@mui/material";
import { Link } from 'react-router-dom';


export default function SignupAndLogin({ closeMobileNav }) {

   if (typeof closeMobileNav !== 'function')
      closeMobileNav = () => { }
   
   return <div className='grid grid-cols-2 gap-2'>
      <Button variant='contained' className='rounded-lg w-full hover:scale-[1.05]' component={Link} to="/signup" onClick={closeMobileNav}>
         Sign Up
      </Button>
      <Button variant='outlined' className='rounded-lg w-full hover:scale-[1.05]' component={Link} to="/login" onClick={closeMobileNav}>
         Login
      </Button>
   </div>
}