const { SCHOOL_TYPES, GENDER } = require("./constants");

function getClassLevelLabel(schoolType) {
	return schoolType ?
		(schoolType === SCHOOL_TYPES.HIGH_SCHOOL ? 'Form' : 'Grade') : 'Level';
}

/**
 * 
 * @param {string} gender 
 */
function getPronouns(gender) {

	let possesive, object, subject;

	switch (gender) {
		case GENDER.MALE:

			possesive = 'his';
			object = 'him';
			subject = 'he';

			break;

		case GENDER.FEMALE:

			possesive = 'her';
			object = 'him';
			subject = 'she';

			break;
	
		default:

			possesive = 'their';
			object = 'them';
			subject = 'they';

			break;
	}

	return { possesive, object, subject }
}

module.exports = {
	getClassLevelLabel,
	getPronouns,
}