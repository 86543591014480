import { IconButton, Link } from "@mui/material";
import { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../../loading";
import request from "../../../../request";
import ClassEditor from "./ClassEditor";
import SchoolContext from '../SchoolContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { requestConfirmation } from "../../../../utils";
import { successToast } from "../../../../toast";
import AssignTeacher from "./AssignTeacher";
import SubjectManager from "./SubjectManager";
import actions from "../../../../redux/actions";
import { connect } from "react-redux";
import CenteredMessage from "../../../../components/CenteredMessage";
import EmptyState from "../../../../components/EmptyState";
import ClassmateTable from "../../../../components/ClassmateTable";
import { getClassLevelLabel } from "../../../../../../shared/utils";


class ClassesUnconnected extends Component {

	static contextType = SchoolContext;

	state = {
		classEditorMode: null,
		selectedClass: null,
		assignTeacherModalOpen: false,
		subjectManagerOpen: false,
	}

	fetchClasses = async () => {

		try {

			showLoading();

			await actions.fetchClasses();

		} catch (err) {
			swal(err.toString());
		} finally {
			hideLoading();
		}
	}

	openClassEditor = (mode, selectedClass=null) => {
		return this.updateState({ classEditorMode: mode, selectedClass })
	}

	closeClassEditor = () => {
		return this.updateState({ classEditorMode: null, selectedClass: null })
	}

	openAssignTeacherModal = (selectedClass) => {
		return this.updateState({ assignTeacherModalOpen: true, selectedClass })
	}

	closeAssignTeacherModal = () => {
		return this.updateState({ assignTeacherModalOpen: false, selectedClass: null })
	}

	openSubjectManager = (selectedClass) => {
		return this.updateState({ subjectManagerOpen: true, selectedClass })
	}

	closeSubjectManager = () => {
		return this.updateState({ subjectManagerOpen: false, selectedClass: null })
	}

	deleteClass = async (cls) => {

		const question = `Are you sure you want to delete class "${cls.name}"?`;
		const confirm = await requestConfirmation(question);

		if (!confirm)
			return;

		try {

			showLoading();

			await request.delete(`/api/admin/classes/${cls._id}`);
			actions.deleteClass(cls._id);

			successToast("Deleted");

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		if (!this.props.classes)
			this.fetchClasses();
	}

	render() {

		const classLevelLabel = getClassLevelLabel(this.context.school.type);

		return <>
			<ClassmateTable
				cols={[
					{
						field: 'name',
						caption: 'NAME'
					},
					{
						field: 'level',
						caption: 'LEVEL',
						render: (level) => {
							return <span className="bg-blue-50 bg-opacity-50 py-[2px] px-[8px] border-[1px] border-blue-100 rounded text-blue-700 text-xs">
								{classLevelLabel} {level}
							</span>
						}
					},
					{
						field: 'students',
						caption: 'STUDENTS',
						fitContent: true,
						rightAlign: {
							onBiggerScreens: true,
						},
						render: students => <span className="bg-blue-50 bg-opacity-50 border-[1px] border-blue-100 text-xs font-bold py-[1px] px-[4px] rounded">
							{students}
						</span>
					},
					{
						field: 'teacher',
						caption: 'TEACHER',
						render: (teacher, cls) => {
							if (teacher) {
								const { name, surname } = teacher.user;
			
								return <div>
									<IconButton onClick={() => this.openAssignTeacherModal(cls) }>
										<EditIcon className="text-xs" />
									</IconButton>
									<span>
										{name} {surname}
									</span>
								</div>
							} else {
								return <span
									className="inline-block bg-[var(--secondary)] text-white text-xs py-1 px-2 shadow-lg rounded-full cursor-pointer"
									onClick={() => this.openAssignTeacherModal(cls)}
								>
									Assign
								</span>
							}
						}
					},
					{
						field: 'subjects',
						caption: 'SUBJECTS',
						render: (_, cls) => {
							return <Link className="cursor-pointer" onClick={() => this.openSubjectManager(cls)}>
								Manage
							</Link>
						}
					},
					{
						field: 'actions',
						caption: '',
						render: (_, cls) => {
							return <>
								<IconButton
									onClick={ () => this.openClassEditor(ClassEditor.MODES.EDIT, cls) }
								>
									<EditIcon className="text-sm" />
								</IconButton>
								<IconButton
									onClick={() => this.deleteClass(cls) }
								>
									<DeleteIcon className="text-sm text-red-600" />
								</IconButton>
							</>
						}
					},
				]}
				rows={this.props.classes}
				fullHeight
				rightAlign={{
					onSmallerScreens: true,
				}}
				emptyState={
					<EmptyState
						title="No classes found"
						subtitle="You havent added any classes to this school"
						onAddButtonClicked={() => this.openClassEditor(ClassEditor.MODES.ADD)}
					/>
				}
				notFetchedState={
					<CenteredMessage
						message={"Classes not loaded"}
						onRefresh={this.fetchClasses}
					/>
				}
				onAdd={this.openClassEditor}
				onRefresh={this.fetchClasses}
			/>

			{
				this.state.assignTeacherModalOpen && <AssignTeacher
					class={this.state.selectedClass}
					close={this.closeAssignTeacherModal}
				/>
			}
			{
				this.state.subjectManagerOpen && <SubjectManager
					class={this.state.selectedClass}
					close={this.closeSubjectManager}
				/>
			}
			{
				this.state.classEditorMode && <ClassEditor
					mode={this.state.classEditorMode}
					class={this.state.selectedClass}
					schoolType={this.context.school.type}
					close={this.closeClassEditor}
				/>
			}
		</>

	}
}

const mapStateToProps = state => ({ classes: state.classes });
const Classes = connect(mapStateToProps)(ClassesUnconnected);
export default Classes;