import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Component } from "react";
import { errorToast, successToast } from "../../toast";
import { hideLoading, showLoading } from "../../loading";
import swal from "sweetalert";
import request from "../../request";
import { createGenderSelectItems, returnChangedAttributes } from "../../utils";
import actions from "../../redux/actions";


export default class ProfileEditor extends Component {

	state = {
		name: '',
		surname: '',
		email: '',
		phone: '',
		gender: '',
	}

	submit = async () => {

		// presence check
		const txtName = document.getElementById('txt-name');
		const txtSurname = document.getElementById('txt-surname');
		const txtEmail = document.getElementById('txt-email');
		const txtGender = document.getElementById('txt-gender');

		const { name, surname, email, phone, gender } = this.state;

		if (!name) {
			errorToast('Provide your name');
			return txtName.focus();
		}

		if (!surname) {
			errorToast('Provide your surname');
			return txtSurname.focus();
		}

		if (!email) {
			errorToast('Provide your email');
			return txtEmail.focus();
		}


		if (!gender) {
			errorToast('Select your gender');
			return txtGender.focus();
		}

		// submit
		const originalData = this.getOriginalData(true);

		const data = returnChangedAttributes({
			name,
			surname,
			email,
			phone,
			gender
		}, originalData);

		if (Object.keys(data).length === 0)
			return errorToast('No changes made');

		try {

			showLoading();

			await request.patch('/api/auth/profile', data);
			successToast('Profile updated successfully');
			
			this.props.close();
			actions.setUser({ ...originalData, ...data })

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}

	}

	getOriginalData(allAttributes=false) {
		const { data } = this.props;
		if (allAttributes)
			return { ...data }
		const { name, surname, email, phone, gender } = data;
		return { name, surname, email, phone, gender }
	}

	componentDidMount() {
		this.setState(this.getOriginalData())
	}

	render() {
		return <Dialog open>
			<DialogTitle>Update profile</DialogTitle>
			<DialogContent dividers>
				<div className="grid grid-cols-2 gap-4">
					<TextField
						id="txt-name"
						label="Name"
						size="small"
						variant="outlined"
						value={this.state.name}
						onChange={e => this.setState({ name: e.target.value })}
						fullWidth
					/>
					<TextField
						id="txt-surname"
						label="Surname"
						size="small"
						variant="outlined"
						value={this.state.surname}
						onChange={e => this.setState({ surname: e.target.value })}
						fullWidth
					/>
					<TextField
						id="txt-email"
						label="Email"
						size="small"
						variant="outlined"
						value={this.state.email}
						onChange={e => this.setState({ email: e.target.value })}
						fullWidth
					/>
					<TextField
						id="txt-phone"
						label="Phone"
						size="small"
						variant="outlined"
						value={this.state.phone}
						onChange={e => this.setState({ phone: e.target.value })}
						fullWidth
					/>
					<TextField
						id="txt-gender"
						label="Gender"
						size="small"
						variant="outlined"
						value={this.state.gender}
						onChange={e => this.setState({ gender: e.target.value })}
						fullWidth
						select
						className="col-span-2"
					>
						{createGenderSelectItems()}
					</TextField>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.submit} size="small" variant="contained">
					SUBMIT
				</Button>
				<Button onClick={this.props.close} size="small">
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}