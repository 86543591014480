import { Button, CircularProgress, Container } from "@mui/material";
import { Component } from "react";
import swal from 'sweetalert';
import request from "../../../../../request";
import CenteredMessage from "../../../../../components/CenteredMessage";
import Timetable from "./Timetable"
import Homework from "../../Homework";
import ReportCard from "./ReportCard";
import PillLabel from "../../../../../components/PillLabel";
import Attendance from "../../../Attendance";
import FullscreenDialog from "../../../../../components/FullscreenDialog";
import TimetableIcon from '@mui/icons-material/CalendarViewMonth';
import ReportIcon from '@mui/icons-material/Assessment';
import { connect } from "react-redux";
import StudentAbout from "../../../StudentAbout";


class _Child extends Component {

	state = {
		student: null,
		loadingStudent: false,
		showingTimetable: false,
		showingReportCard: false,
	}

	fetchData = async () => {
		try {

			this.setState({ loadingStudent: true });

			const res = await request.get(`/api/general/students/${this.props._id}`);
			const student = res.data;

			this.setState({ student });

		} catch (err) {
			swal(String(err));
		} finally {
			this.setState({ loadingStudent: false });
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {

		let subjectsInfo;

		if (this.state.loadingStudent) {
			subjectsInfo = <div className="py-[200px]">
				<div className="text-center">
					<CircularProgress />
				</div>
			</div>
		} else if (this.state.student) {
			subjectsInfo = <div className="[&>:last-child]:border-b-[0px] [&>*]:border-b-[1px] [&>*]:border-b-gray-200">
				{
					this.state.student.class_subjects.map((cs, index) => {
						return <div key={index} className="text-gray-600 py-2">
							<div className="text-xl font-bold">{cs.subject.name}</div>
							{
								cs.teacher && <div className="text-sm">
									Taught by <span className="font-bold">{cs.teacher.user?.name} {cs.teacher.user?.surname}</span>
								</div>
							}
							{
								typeof cs.average_mark === 'number' && <div className="text-sm">
									<span className={`font-bold ${cs.average_mark >= 50 ? 'text-green-600' : 'text-red-600'}`}>
										{cs.average_mark.toFixed(0)}
									</span> average mark
								</div>
							}
							
						</div>
					})
				}
			</div>
		} else {
			subjectsInfo = <div className="h-[400px]">
				<CenteredMessage
					message={"Subject information not fetched"}
					onRefresh={this.fetchData}
				/>
			</div>
		}

		const isSmallScreen = [ 'xs', 'sm' ].includes(this.props.device.size);

		const reportCard = this.state.showingReportCard && <ReportCard
			close={() => this.setState({ showingReportCard: false })}
			student={this.props}
		/>

		const timetable = this.state.showingTimetable && <Timetable
			student={this.props}
			close={() => this.setState({ showingTimetable: false })}
		/>

		const timeTableAndReportCardButtons = <>
			<Button
				onClick={() => this.setState({ showingReportCard: true })}
				startIcon={<ReportIcon />}
			>
				REPORT CARD
			</Button>
			<Button
				onClick={() => this.setState({ showingTimetable: true })}
				startIcon={<TimetableIcon />}
				className={"ml-4"}
			>
				TIMETABLE
			</Button>
		</>

		const title = <div className="grid grid-cols-[1fr,auto] gap-4">
			<div className="text-2xl font-bold">
				{this.props.user.name} {this.props.user.surname}
			</div>
			<div>
				{!isSmallScreen && timeTableAndReportCardButtons}
			</div>
		</div>

		const Wrapper = isSmallScreen ? props => <div {...props} /> : Container;

		const body = <Wrapper>
			<StudentAbout
				student={{
					...this.props,
					...this.state.student
				}}
			/>

			<div className="mt-4">
				<Attendance studentId={this.props._id} />
			</div>

			<div className="mt-6 p-4 rounded border-[1px] border-gray-200">
				<PillLabel color="secondary">SUBJECTS</PillLabel>
				<div className="mt-1">{subjectsInfo}</div>
			</div>

			<div className="mt-6 p-4 rounded border-[1px] border-gray-200">
				<PillLabel color="secondary">HOMEWORK</PillLabel>
				<div className="mt-2">
					<Homework studentId={this.props._id} />
				</div>
				
			</div>
			{timetable}
			{reportCard}
		</Wrapper>

		return  <FullscreenDialog
			title={title}
			body={body}
			close={this.props.close}
			actions={
				isSmallScreen ? 
					<div className="w-full text-center">
						{timeTableAndReportCardButtons}
					</div> : undefined
			}
		/>
	}

}


const mapStateToProps = state => ({ device: state.device });
const Child = connect(mapStateToProps)(_Child);

export default Child;
