import { Component } from "react";
import { connect } from "react-redux";
import StudentAbout from '../../StudentAbout';


class SummaryUnconnected extends Component {
	render() {

		const { user } = this.props;
		const { school, student } = user;
		return <StudentAbout student={{ ...student, user, school }} />
	}
}

const mapStateToProps = state => {
	return {
		user: state.user
	}
}

const Summary = connect(mapStateToProps)(SummaryUnconnected);
export default Summary;