
import swal from 'sweetalert';
import withRouterParams from '../withRouterParams';
import Page from './Page';
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import MetaTitle from '../components/MetaTitle';


class VerifyAccountVanilla extends Page {

	state = {
		success: false,
	}

	getBy() {
		return this.props.params.by.substring(3)
	}

	verify = async () => {

		try {

			showLoading();

			const { ref_code } = this.props.params;
			const by = this.getBy();
			const data = { ref_code, by };
			await request.post('/api/auth/profile/verification', data);

			await this.updateState({ success: true });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		super.componentDidMount();
		this.verify();
	}

	_render() {

		let btn, msg;
		const by = this.getBy();

		if (this.state.success) {
			msg = <>
				You have successfully verified your account. Login with your <b>{by}</b> to  start using Classmate.
			</>

			btn = <Button variant='contained' size='small' className='mt-2' component={Link} to="/login">
				LOGIN
			</Button>

		} else {

			msg = <>
				Verify your <b>{by}</b> to access your account.
			</>

			btn = <Button onClick={this.verify} variant='contained' size='small'>
				Verify
			</Button>
		}

		return <>
			<MetaTags>
				<MetaTitle>Verify Your Account</MetaTitle>
			</MetaTags>
			<div className='page-height flex justify-center items-center'>
				<div className='p-6 w-full mx-4 max-w-[400px] border-b-[grey] border-b-[1px]'>
					<p className='text-xl text-gray-600'>
						{msg}
					</p>
					<div className='mt-2'>
						{btn}
					</div>
				</div>
			</div>
		</>
	}
}

const VerifyAccount = withRouterParams(VerifyAccountVanilla);
export default VerifyAccount;