import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Component } from "react";
import { TERMS_PER_YEAR } from "../../../../../../shared/constants";
import { errorToast, successToast } from "../../../../toast";
import { requestConfirmation } from "../../../../utils";
import { hideLoading, showLoading } from "../../../../loading";
import request from "../../../../request";
import swal from 'sweetalert';

export class NewTerm extends Component {

	state = {
		year: new Date().getFullYear(),
		term: 1,
		starts: '',
	}

	submit = async () => {
		// presence check
		const txtYear = document.getElementById('txt-year');
		const txtTerm = document.getElementById('txt-term');
		const txtStartDate = document.getElementById('txt-start-date')

		const { year, term, starts } = this.state;

		if (!year) {
			errorToast('Enter year');
			return txtYear.focus();
		}

		if (!term) {
			errorToast('Provide term');
			return txtTerm.focus();
		}

		if (!starts) {
			errorToast('Select term start date');
			return txtStartDate.focus();
		}

		// confirm
		const question = `You are about to change the current term to "Term ${term} (${year})".This will increase outstanding fees by the set fees for all the students, among other things. Do you want to continue?`
		const confirm = await requestConfirmation(question);

		if (!confirm)
			return;

		// save
		try {

			showLoading();

			const data = {
				year,
				term,
				starts: new Date(starts).getTime(),
			}

			await request.post('/api/admin/terms/current', data);
			successToast('New term started');
			this.props.close({ year, term, starts });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {

		if (this.props.currentTerm) {
			// set to next term
			let { year, term } = this.props.currentTerm;
			
			if (term === TERMS_PER_YEAR) {
				term = 1;
				year += 1;
			} else {
				term += 1
			}

			this.setState({ year, term });
		}
	}

	render() {
		return <Dialog open>
			<DialogTitle>
				New Term
			</DialogTitle>
			<DialogContent dividers>
				<div className="[&>*]:mt-4">
					<div className="grid grid-cols-2 gap-4">
						<TextField
							id="txt-year"
							label="Year"
							value={this.state.year}
							onChange={e => this.setState({ year: e.target.value })}
							size="small"
							variant="outlined"
							type="number"
							InputProps={{ inputProps: { min: new Date().getFullYear(), max: new Date().getFullYear() + 1 } }}
						/>
						<TextField
							id="txt-term"
							label="Term"
							value={this.state.term}
							onChange={e => this.setState({ term: e.target.value })}
							size="small"
							variant="outlined"
							type="number"
							InputProps={{ inputProps: { min: 1, max: TERMS_PER_YEAR } }}
						/>
					</div>

					<TextField
						id="txt-start-date"
						label="Start date"
						value={this.state.starts}
						onChange={e => this.setState({ starts: e.target.value })}
						size="small"
						variant="outlined"
						type="date"
						InputProps={{ inputProps: { min: this.props.currentTerm?.starts } }}
						InputLabelProps={{ shrink: true }}
					/>

				</div>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" size="small" onClick={this.submit}>
					SAVE
				</Button>
				<Button size="small" onClick={() => this.props.close()}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}