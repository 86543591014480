import Page from "../Page";
import { MetaTags } from "react-meta-tags";
import CanonicalURL from "../../components/CanonicalURL";
import Hero from "./Hero";
import Features from "./Features";


export default class Home extends Page {
   _render() {
      return <>
         <MetaTags>
            <title>Classmate – A school management system from Xavisoft Digital</title>
            <meta name="description" content="Classmate is a platform that connects students, teachers, and parents. It is a one-stop solution for all stakeholders in the education system. Simplifies school management, boosts parental involvement." />
            <CanonicalURL />
         </MetaTags>

         <Hero />
         <Features />
      </>
   }
}