
/**
 * 
 * @param {object} param0 
 * @param {"primary"|"secondary"|"tertiary"|undefined} param0.color
 * @returns 
 */
export default function PillLabel({ children, color="tertiary" }) {

	let bgColor, textColor = 'text-white';

	switch (color) {
		case 'primary':
			bgColor = 'bg-[var(--primary)]';
			break;
		case 'secondary':
			bgColor = 'bg-[var(--secondary)]';
			break;
		default:
			bgColor = 'bg-gray-200';
			textColor = 'text-gray-600';
	}

	return <span className={`px-2 py-1 ${bgColor} ${textColor} text-xs rounded-full`}>{children}</span>;
}