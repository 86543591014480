import { Component } from "react";
import Loading from "../../../../../components/Loading";
import { Alert } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClassmateIconButton from "../../../../../components/ClassmateIconButton";

export default class TabPanel extends Component {

	componentDidMount() {
		if (!this.props.loaded)
			this.props.onRefresh();
	}

	render() {

		const { heading, body, footer, loading, error, onRefresh, onDismissError } = this.props;
		
		const loadingJSX = loading && <Loading />

		const errorJSX = error && <Alert onClose={onDismissError} severity="error">
			{error}
		</Alert>


		return <div className="h-full grid grid-rows-[auto,1fr,auto]">

			<div className="text-2xl text-gray-600 font-bold my-4">
				{heading}
			</div>

			<div className="overflow-y-auto">
				{
					loadingJSX || <>
						{errorJSX}
						{body}
					</>
				}
			</div>

			<div>
				{
					!loading && <div className="grid grid-cols-[1fr,auto] mt-2 gap-2">
						{footer}
						<div className="text-right">
							<ClassmateIconButton size="small" color="secondary" onClick={onRefresh}>
								<RefreshIcon />
							</ClassmateIconButton>
						</div>
					</div>
				}
			</div>

		</div>
	}
}