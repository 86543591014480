import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";


export default function SubjectAttendance(props) {

	return <Dialog open>
		<DialogTitle>Subject Attendance</DialogTitle>
		<DialogContent dividers>
			{
				props.items.map(item => {
					return <div className="text-gray-600 w-[300px] max-w-full my-4">
						<div className="grid grid-cols-[1fr,auto]">
							<div className="text-xl truncate">
								{item.name}
							</div>
							<div className="text-xl font-bold">
								{item.average}%
							</div>
						</div>
						<div className="mt-2">
							<LinearProgress
								variant="determinate"
								value={item.average}
							/>
						</div>
					</div>
				})
			}
		</DialogContent>
		<DialogActions>
			<Button onClick={props.close} size="small">
				CLOSE
			</Button>
		</DialogActions>
	</Dialog>
}