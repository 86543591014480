import { Container } from "@mui/material";
import Device from "./Device";
import Actions from "./Actions";

export default function Hero() {
	return <section className="page-height flex items-center">
		<div className="w-full">
			<Container>
				<div className="grid md:grid-cols-2 gap-3 border-b-[1px] border-b-gray-200 pb-8 sm:border-b-[#0000] sm:pb-0">
					<div className="flex h-full items-center">
						<div>
							<div className="font-bold text-3xl sm:text-5xl">
								<div className="text-[var(--primary)]">
									One app for
								</div>
								<div className="text-[var(--secondary)]">
									all stakeholders
								</div>
							</div>

							<p className="text-gray-600 text-sm sm:text-lg my-4">
								Classmate is a platform that connects students, teachers, and parents. It is a one-stop solution for all stakeholders in the education system. Simplifies school management, boosts parental involvement.
							</p>

							<Actions />
						</div>
					</div>
					<div className="hidden md:flex h-full items-center justify-center">
						<div className="w-[90%]">
							<Device />
						</div>
					</div>
				</div>
			</Container>
		</div>
	</section>
}