import { Button } from "@mui/material";
import Feature from "./Feature";
import { Link } from "react-router-dom";
import ComingSoonIcon from '@mui/icons-material/QueryBuilder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function Plan({ name, price, features, comingSoon=false }) {

	const Icon = comingSoon ? ComingSoonIcon : CheckCircleIcon;
	const iconTextColorClass = comingSoon ? 'text-gray-600' : 'text-green-600';

	return <div className="px-4 py-8 border border-gray-200 rounded-lg shadow-md relative overflow-hidden">
		<div className="h-full grid grid-rows-[1fr,auto]">
			<div>
				<div className="text-2xl font-extrabold text-gray-600">{name}</div>
				<div className="flex my-4">
					<div className="text-4xl font-bold text-[var(--primary)]">
						${comingSoon ? '##.##' : price.toFixed(2) }
					</div>
					<div className="text-gray-500 ml-2">/ month</div>
				</div>
				<div className="mt-4">
					{features.map((feature, i) => <Feature key={i} {...feature} />)}
				</div>
			</div>
			<Button className="mt-4 py-2 px-4 rounded-full" fullWidth variant="contained" component={Link} to="/signup" disabled={comingSoon}>
				{comingSoon ? 'Coming soon' : 'Get Started'}
			</Button>
		</div>

		<div className={`absolute aspect-square flex items-center justify-center rounded-bl-[60%] top-0 right-0 p-2 ${iconTextColorClass} border-b-[1px] border-l-[1px]`}>
			<Icon color="inherit" />
		</div>
	</div>
}