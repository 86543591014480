import { IconButton } from "@mui/material";
import { Component } from "react";
import { hideLoading, showLoading } from "../../../../loading";
import swal from "sweetalert";
import request from "../../../../request";
import AddTeacherOrStudent from "../AddTeacherOrStudent";
import { requestConfirmation } from "../../../../utils";
import { successToast } from "../../../../toast";
import { MODES as AddTeacherOrStudentModes } from "../AddTeacherOrStudent";
import EmptyState from '../../../../components/EmptyState';
import CenteredMessage from '../../../../components/CenteredMessage';
import ClassmateTable from "../../../../components/ClassmateTable";
import PillLabel from "../../../../components/PillLabel";
import DeleteIcon from '@mui/icons-material/Delete';
import Gender from "../../../../components/Gender";
import Email from "../../../../components/Email";
import Phone from "../../../../components/Phone";


export default class Teachers extends Component {

   state = {
      teachers: null,
      teacherEditorOpen: false,
   }

   openTeacherEditor = () => {
      this.setState({ teacherEditorOpen: true });
   }

   closeTeacherEditor = () => {
      this.setState({ teacherEditorOpen: false });
   }

   deleteTeacher = async (teacher) => {

      const confirm = await requestConfirmation(`Are you sure you want to delete "${teacher.user.name} ${teacher.user.surname}"?`);

      if (!confirm)
         return;

      try {
            
         showLoading();

         await request.delete(`/api/admin/teachers/${teacher._id}`);

         successToast("Teacher deleted successfully");

         const teachers = this.state.teachers.filter(t => t._id !== teacher._id);
         this.updateState({ teachers });

      } catch (err) {
         swal(err.toString());
      } finally {
         hideLoading();
      }

   }



   fetchTeachers = async () => {
      try {

         showLoading();

         const res = await request.get('/api/admin/teachers');
         const teachers = res.data;

         this.setState({ teachers });

      } catch (err) {
         swal(err.toString());
      } finally {
         hideLoading();
      }
   }

   addTeacher = (teacher) => {
      const teachers = [ ...this.state.teachers, teacher ];
      return this.updateState({ teachers });
   }

   componentDidMount() {
      this.fetchTeachers();
   }

   render() {

      return <>
         <ClassmateTable
            cols={[
               {
                  field: 'teacher_no',
                  caption: 'TEACHER NO',
                  render: teacherNo => <PillLabel>{teacherNo}</PillLabel>
               },
               {
                  field: 'user',
                  caption: 'NAME',
                  render: user => user.name 
               },
               {
                  field: 'user',
                  caption: 'SURNAME',
                  render: user => user.surname 
               },
               {
                  field: 'user',
                  caption: 'GENDER',
                  render: user => <Gender gender={user.gender} />,
                  fitContent: true,
               },
               {
                  field: 'user',
                  caption: 'EMAIL',
                  render: user => <Email email={user.email} />
               },
               {
                  field: 'user',
                  caption: 'PHONE',
                  render: user => <Phone phone={user.phone} />
               },
               {
                  field: 'class',
                  caption: 'CLASS',
                  render: cls => cls?.name || '–'
               },
               {
                  field: 'actions',
                  caption: '',
                  render: (_, teacher) => {
                     return <>
                        <IconButton
                           color="error"
                           onClick={() => this.deleteTeacher(teacher)}
                           size="small"
                        >
                           <DeleteIcon />
                        </IconButton>
                     </>
                  } 
               },
            ]}
            rows={this.state.teachers}
            fullHeight
            emptyState={
               <EmptyState
                  title="No teachers"
                  subtitle="You haven't added any teachers yet"
                  onAddButtonClicked={this.addTeacher}
               />
            }
            notFetchedState={
               <CenteredMessage
                  message={"Teachers not loaded"}
                  onRefresh={this.fetchTeachers}
               />
            }
            onAdd={this.openTeacherEditor}
            onRefresh={this.fetchTeachers}
         />

         {
            this.state.teacherEditorOpen && <AddTeacherOrStudent
               close={this.closeTeacherEditor}
               mode={AddTeacherOrStudentModes.TEACHER}
               addToList={this.addTeacher}
            />
         }
      </>
   }
}