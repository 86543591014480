

const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
const SET_ROLE = 'SET_ROLE';
const SET_SUBJECTS = 'SET_SUBJECTS';
const SET_USER  = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';
const SET_CLASSES = 'SET_CLASSES';
const ADD_CLASS = 'ADD_CLASS';
const UPDATE_CLASS = 'UPDATE_CLASS';
const DELETE_CLASS = 'DELETE_CLASS';
const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
const SET_IS_PWA = 'SET_IS_PWA';
const SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG = 'SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG';
const SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS = 'SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS';
const SET_DEVICE = 'SET_DEVICE';

export {
   ADD_CLASS,
   DELETE_CLASS,
   SET_AUTHENTICATED,
   SET_CLASSES,
   SET_CURRENT_ROUTE,
   SET_DEVICE,
   SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG,
   SET_IGNORE_PUSH_NOTIFICATIONS_REGISTRATION_STATUS,
   SET_IS_PWA,
   SET_ROLE,
   SET_SUBJECTS,
   SET_USER,
   UPDATE_CLASS,
   UPDATE_USER,
}