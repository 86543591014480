import { Component } from "react";

const BASE_URL = 'https://classmate.co.zw';

export default class CanonicalURL extends Component {

	state = {
		href: ''
	}

	componentDidMount() {

		let href;

		if (this.props.url) {
			href = this.props.url;
		} else {
			let path;
			if (this.props.path)
				path = this.props.path
			else
				path = window.location.pathname;
			href = `${BASE_URL}${path}`
		}

		this.setState({ href });

	}

	render() {
		return <link
			rel="canonical"
			href={this.state.href}
		/>
	}
}