
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from 'prop-types';
import CenteredMessage from "../../../../components/CenteredMessage";
import { connect } from "react-redux";

function AttendanceListUnconnected(props) {

	const { students, device } = props;
	const isSmallScreen = [ 'xs', 'sm' ].includes(device.size);

	let tableBody;

	if (students.length === 0) {
		tableBody = <TableRow>
			<TableCell colSpan={100}>
				<div className="h-[400px]">
					<CenteredMessage
						message={"No students enrolled in this class & subject"}
						onRefresh={props.refresh}
					/>
				</div>
			</TableCell>
		</TableRow>
	} else {
		tableBody = students.map(student => {
			return <TableRow>
				{ !isSmallScreen && <TableCell>{student.student_no}</TableCell> }
				<TableCell>{student.user?.name}</TableCell>
				<TableCell>{student.user?.surname}</TableCell>
				{ !isSmallScreen && <TableCell>{student.user?.gender}</TableCell> }
				<TableCell>
					<Checkbox
						value={props.attendanceList.includes(student)}
						onChange={
							e => {
								const present = e.target.checked;
								props.onAttendanceListChange(student, present);
							}
						}
					/>
				</TableCell>
			</TableRow>
		});
	}

	return <Table size="small" stickyHeader>
		<TableHead>
			<TableRow>
				{!isSmallScreen && <TableCell>STUDENT NO</TableCell>}
				<TableCell>NAME</TableCell>
				<TableCell>SURNAME</TableCell>
				{!isSmallScreen && <TableCell>GENDER</TableCell>}
				<TableCell>PRESENT</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{tableBody}
		</TableBody>
	</Table>
}

const mapStateToProps = state => ({ device: state.device });
const AttendanceList = connect(mapStateToProps)(AttendanceListUnconnected);

AttendanceList.propTypes = {
	refresh: PropTypes.func.isRequired,
	onAttendanceListChange: PropTypes.func.isRequired,
	students: PropTypes.arrayOf(PropTypes.object).isRequired,
	attendanceList: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AttendanceList;

