
import DocumentIcon from '@mui/icons-material/Article';
import TimeAgo from 'react-timeago';
import { Component } from "react";
import Display from "./Display";


export default class Thumbnail extends Component {

	state = {
		showContents: false,
	}

	showContents = () => {
		return this.updateState({ showContents: true });
	}

	hideContents = () => {
		return this.updateState({ showContents: false });
	}

	render() {

		let display;

		if (this.state.showContents) {
			display = <Display
				{...this.props}
				close={this.hideContents}
				onDelete={this.props.onDelete}
				isHomework={this.props.isHomework}
				allowEditing={this.props.allowEditing}
			/>
		}

		return <>
			<div className="rounded border-solid border-[#ccc] border-[1px] p-2 cursor-pointer" onClick={this.showContents}>
				<div className="text-lg text-gray-600 truncate">
					{this.props.name}
				</div>
				<div className="grid grid-cols-[auto,1fr] gap-1">
					<div className="h-full flex items-center">
						<DocumentIcon color="primary" />
					</div>
					<div className="h-full flex items-center text-xs text-gray-400">
						<TimeAgo date={this.props.createdAt} />
					</div>
				</div>
			</div>

			{display}
		</>
	}
}