
import ReportCardComponent from "../../../ReportCard";
import FullscreenDialog from "../../../../../components/FullscreenDialog";


export default function ReportCard(props) {

	const { name, surname } = props.student.user
	const { _id: studentId, school } = props.student;

	return <FullscreenDialog
		title={`${name} ${surname}'s report card`}
		body={
			<ReportCardComponent
				studentId={studentId}
				currentTerm={school?.current_term}
			/>
		}
		close={props.close}
	/>
}