import { Component } from "react";
import Summary from "./Summary";
import { Parents } from "./Parents";
import Attendance from "../../Attendance";


export default class Dashboard extends Component {

	render() {
		return <div className="pb-2">
			<Summary />
			<Spacer />
			<Parents />
			<Spacer />
			<Attendance />
		</div>
	}
}

function Spacer() {
	return <div className="h-[20px]" />
}