
import ExpandIcon from '@mui/icons-material/ChevronRight';
import FeesIcon from '@mui/icons-material/PaidOutlined';

export default function ChildThumbnail(props) {

	const { user, school, outstanding_fees, onClick } = props;

	return <div className="p-4 hover:p-5 cursor-pointer grid grid-cols-[1fr,auto] gap-2 shadow text-gray-600" onClick={onClick}>
		<div className="overflow-hidden">
			<div className="capitalize text-lg font-bold">
				{user.name} {user.surname}
			</div>
			<div className="text-sm">
				{school.name}
			</div>
			<div className='text-sm italic truncate'>
				{school.address}
			</div>
			<div className="text-sm flex items-center mt-1">

				<FeesIcon className='mr-1 text-gray-500' />

				{
					outstanding_fees <= 0 ?
						<span className='text-green-600 font-semibold'>
							Paid
						</span>:
						<span className='text-[red]'>
							<b>{outstanding_fees.toFixed(2)}</b> outstanding
						</span>

				}
			</div>
		</div>
		<div className="flex items-center text-gray-400">
			<ExpandIcon />
		</div>
	</div>
}