import { Tab, Tabs } from "@mui/material";
import { Component } from "react";
import request from "../../../../../request";
import Tests from "./Tests";
import Homework from "./Homework";
import Students from "./Students";
import LessonPlans from "./LessonPlans";
import { connect } from "react-redux";
import FullscreenDialog from "../../../../../components/FullscreenDialog";
import StudentIcon from '@mui/icons-material/School';
import HomeworkIcon from '@mui/icons-material/Assignment';
import TestIcon from '@mui/icons-material/Quiz';
import LessonPlanIcon from '@mui/icons-material/Folder';


const TABS = {
	STUDENTS: 'students',
	TESTS: 'tests',
	HOMEWORK: 'homework',
	LESSON_PLANS: 'lesson-plans',
}

const PAGE_SIZE = 10;

class SubjectUnconnected extends Component {

	state = {
		students: null,
		loadingStudents: false,
		loadingStudentsError: null,
		homework: null,
		loadingHomework: false,
		loadingHomeworkError: null,
		homeworkPage: 1,
		homeworkCount: 0,
		tests: null,
		loadingTests: false,
		loadingTestsError: null,
		testsPage: 1,
		testCount: 0,
		lessonPlans: null,
		loadingLessonPlans: false,
		loadingLessonPlansError: null,
		lessonPlansPage: 1,
		lessonPlansCount: 0,
		tab: TABS.STUDENTS,
	}

	getClassSubjectId() {
		return this.props._id;
	}

	fetchStudents = async () => {

		try {

			await this.updateState({ loadingStudents: true });
			const res = await request.get(`/api/teacher/class-subjects/${this.getClassSubjectId()}`);
			const students = res.data.students;

			await this.updateState({ students });

		} catch (err) {
			const loadingStudentsError = String(err);
			await this.updateState({ loadingStudentsError });
		} finally {
			await this.updateState({ loadingStudents: false });
		}
	}

	fetchHomework = async () => {

		try {

			await this.updateState({ loadingHomework: true });
			const params = { page: this.state.homeworkPage, limit: PAGE_SIZE }

			if (this.props.currentTerm) {
				const { year, term } = this.props.currentTerm;
				params.year = year;
				params.term = term;
			}
			
			const res = await request.get(`/api/general/class-subjects/${this.getClassSubjectId()}/homework`, { params });

			const homework = res.data.homework;
			const homeworkCount = res.data.count;
			await this.updateState({ homework, homeworkCount });

		} catch (err) {
			const loadingHomeworkError = String(err);
			await this.updateState({ loadingHomeworkError });
		} finally {
			await this.updateState({ loadingHomework: false });
		}
	}

	displayHomeworkFirstPage = async () => {
		await this.updateState({ homeworkPage: 1 });
		this.fetchHomework();
	}

	fetchTests = async () => {

		try {

			await this.updateState({ loadingTests: true });
			const params = { page: this.state.testsPage, limit: PAGE_SIZE }

			if (this.props.currentTerm) {
				const { year, term } = this.props.currentTerm;
				params.year = year;
				params.term = term;
			}

			const res = await request.get(`/api/teacher/class-subjects/${this.getClassSubjectId()}/tests`, { params });

			const tests = res.data.tests;
			const testCount = res.data.count;
			await this.updateState({ tests, testCount });

		} catch (err) {
			const loadingTestsError = String(err);
			await this.updateState({ loadingTestsError });
		} finally {
			await this.updateState({ loadingTests: false });
		}
	}

	displayTestsFirstPage = async () => {
		await this.updateState({ testsPage: 1 });
		this.fetchTests();
	}

	fetchLessonPlans = async () => {

		try {

			await this.updateState({ loadingLessonPlans: true });
			const params = { page: this.state.lessonPlansPage, limit: PAGE_SIZE }

			if (this.props.currentTerm) {
				const { year, term } = this.props.currentTerm;
				params.year = year;
				params.term = term;
			}

			const res = await request.get(`/api/teacher/class-subjects/${this.getClassSubjectId()}/lesson-plans`, { params });

			const lessonPlans = res.data.lesson_plans;
			const lessonPlansCount = res.data.count;
			await this.updateState({ lessonPlans, lessonPlansCount });

		} catch (err) {
			const loadingLessonPlansError = String(err);
			await this.updateState({ loadingLessonPlansError });
		} finally {
			await this.updateState({ loadingLessonPlans: false });
		}
	}

	displayLessonPlansFirstPage = async () => {
		await this.updateState({ lessonPlansPage: 1 });
		this.fetchLessonPlans();
	}

	render() {

		let dialogContent;

		switch (this.state.tab) {
			case TABS.STUDENTS:

				dialogContent = <Students
					students={this.state.students}
					loading={this.state.loadingStudents}
					error={this.state.loadingStudentsError}
					onRefresh={this.fetchStudents}
					onDismissError={() => this.updateState({ loadingStudentsError: null })}
				/>
				
				break;

			case TABS.TESTS:

				dialogContent = <Tests
					tests={this.state.tests}
					loading={this.state.loadingTests}
					error={this.state.loadingTestsError}
					onRefresh={this.fetchTests}
					onDismissError={() => this.updateState({ loadingTestsError: null })}
					onAdd={this.displayTestsFirstPage}
					count={this.state.testCount}
					page={this.state.testsPage}
					pageSize={PAGE_SIZE}
					onPageChange={page => this.updateState({ testsPage: page })}
				/>

				break;

			case TABS.HOMEWORK:

				dialogContent = <Homework
					homework={this.state.homework}
					loading={this.state.loadingHomework}
					error={this.state.loadingHomeworkError}
					onRefresh={this.fetchHomework}
					onDismissError={() => this.updateState({ loadingHomeworkError: null })}
					onPageChange={page => this.updateState({ homeworkPage: page })}
					onAdd={this.displayHomeworkFirstPage}
					count={this.state.homeworkCount}
					page={this.state.homeworkPage}
					pageSize={PAGE_SIZE}
				/>

				break;

			case TABS.LESSON_PLANS:
				
				dialogContent = <LessonPlans
					classSubjectId={this.getClassSubjectId()}
					lessonPlans={this.state.lessonPlans}
					loading={this.state.loadingLessonPlans}
					error={this.state.loadingLessonPlansError}
					onRefresh={this.fetchLessonPlans}
					onDismissError={() => this.updateState({ loadingLessonPlansError: null })}
					onDelete={this.displayLessonPlansFirstPage}
					count={this.state.lessonPlansCount}
					page={this.state.lessonPlansPage}
					pageSize={PAGE_SIZE}
				/>
				
				break;
		
			default:
				break;
		}

		const isSmallScreen = [ 'xs', 'sm' ].includes(this.props.device.size);

		const tabs = <Tabs
			value={this.state.tab}
			onChange={ (_, tab) => this.updateState({ tab })}
			variant="scrollable"
		>
			<Tab
				label={isSmallScreen ? undefined : "Students"}
				icon={<StudentIcon />}
				value={TABS.STUDENTS}
				iconPosition="start"
			/>
			<Tab
				label={isSmallScreen ? undefined : "Tests"}
				icon={<TestIcon />}
				value={TABS.TESTS}
				iconPosition="start"
			/>
			<Tab
				label={isSmallScreen ? undefined : "Homework"}
				icon={<HomeworkIcon />}
				value={TABS.HOMEWORK}
				iconPosition="start"
			/>
			<Tab
				label={isSmallScreen ? undefined : "Lesson plans"}
				icon={<LessonPlanIcon />}
				value={TABS.LESSON_PLANS}
				iconPosition="start"
			/>
		</Tabs>

		const title = <div className="md:grid grid-cols-[auto,1fr]">
			<div className="h-full flex items-center pr-8 mr-8 border-r-gray-400 md:border-r-2 text-gray-600 text-2xl font-bold">
				{this.props.subject.name} — {this.props.class.name}
			</div>

			{
				!isSmallScreen && <div className="h-full flex items-center">
					{tabs}
				</div>
			}
		</div>

		if (isSmallScreen) {
			dialogContent = <div className="h-full grid grid-rows-[1fr,auto] overflow-hidden gap-2">
				<div className="overflow-scroll">
					{dialogContent}
				</div>
				<div className="border-t-gray-200 border-t-[1px] overflow-scroll">
					{tabs}
				</div>
			</div>
		}

		return <FullscreenDialog
			title={title}
			body={dialogContent}
			close={this.props.close}
		/>
	}
}


const mapStateToProps = state => ({
	currentTerm: state.user?.school?.current_term,
	device: state.device
});

const Subject = connect(mapStateToProps)(SubjectUnconnected);

export default Subject;