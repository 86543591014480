import { SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG } from "./constants";


export default function displayPushNotificationsOptinDialogReducer(state = false, action) {
	switch (action.type) {
		case SET_DISPLAY_PUSH_NOTIFICATIONS_OPTIN_DIALOG:
			return action.payload;
		default:
			return state;
	}
}