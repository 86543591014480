import { Divider } from "@mui/material";
import { getCaption, isColumnRightAligned, renderCell } from "./utils";


export default function Card({ cols, row, rightAlign }) {
	
	return <div className="rounded-md border-[1px] border-[#ddd] grid grid-cols-2 [&>*]:my-2 text-xs p-4">
		{
			cols.map((col, index) => {

				const caption = getCaption(col);
				const cell = renderCell(row, col);
				const rightAligned = isColumnRightAligned(rightAlign, col.rightAlign, true);
				let body;

				if (caption === '') {
					body = <div className="col-span-2 flex justify-center">
						{cell}
					</div>
				} else {
					body = <>
						<div className="font-bold">
							{caption}
						</div>
						<div className={`${rightAligned ? 'flex justify-end' : ''} truncate`}>
							{cell}
						</div>						
					</>
				}

				return <>
					{body}
					{(index !== cols.length - 1) && <Divider className="col-span-2" /> }
				</>
			})
		}
	</div>
}