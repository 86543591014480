import { Container, Divider } from "@mui/material";
import Actions from "./Actions";
import DoneIcon from '@mui/icons-material/CheckCircle';

const features = [
	{
		title: 'Student enrolment',
		description: "Effortlessly manage student enrollment and class assignments with automatic advancement features, keeping your records accurate and up-to-date."
	},
	{
		title: 'Timetable',
		description: "Simplify timetable creation with our automated timetable generator. Set your constraints, and let our system craft the perfect timetable for your school."
	},
	{
		title: "Fees Management",
		description: "Easily set and track fees. Quickly see which students have paid and who still owes, ensuring smooth financial operations."
	},
	{
		title: "Accounting",
		description: "Access detailed accounting reports, including income statements and cash flow. Integrate seamlessly with your existing system or use our built-in invoicing tools."
	},
	{
		title: "Lesson planning",
		description: "Allow teachers to efficiently record and organize their lesson plans for the upcoming term, streamlining preparation and collaboration."
	},
	{
		title: "Parent Portal",
		description: "Empower parents with real-time access to their child's attendance records and grades. Notifications keep them informed about homework assignments, fostering better communication.",
	},
	{
		title: "Attendance",
		description: "Accurately record and track student attendance to maintain reliable records and improve school accountability."
	}
]

function Feature({ title, description }) {
	return <div className="shadow p-8 bg-white">
			
		<div className="grid gap-2 grid-cols-[auto,1fr]">
			<div className="text-2xl text-green-600 mb-4 flex items-center h-full">
				<DoneIcon fontSize="inherit" />
			</div>
			<h3 className="font-bold text-xl flex items-center h-full">{title}</h3>
		</div>

		<Divider className="my-4" />
		<p className="text-lg mt-1 text-left text-gray-500 text-left">
			{description}
		</p>
	</div>
}

export default function Features() {

	return <section className="text-gray-600 py-16 sm:py-32 border-t-[1px] bg-gray-50">
		<Container>
			<h2 className="text-4xl font-extrabold text-center">Features</h2>

			<div className="grid grid-cols-1 sm:grid-cols-2 gap-8 md:gap-16 my-8 sm:my-16">
				{features.map(feature => <Feature {...feature} />)}
			</div>

			<Actions />

		</Container>

	</section>
}