
import Logo from '../../components/Logo';

export default function Device() {
	return <div
		style={{ perspective: 150 }}
	>
		<div
			className='aspect-[16/10] border-solid border-gray-700 border-[10px] rounded-xl flex items-center justify-center pointer-events-none'
			style={{ transform: 'rotateZ(20deg) rotateX(-30deg) rotateY(-30deg)'}}
		>
			<Logo />
		</div>
	</div>
}