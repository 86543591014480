

import React from 'react';
import { connect } from 'react-redux';
import { hideLoading, showLoading } from '../../../../loading';
import swal from 'sweetalert';
import request from '../../../../request';
import actions from '../../../../redux/actions';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import SchoolContext from '../SchoolContext';


class SubjectPickerUnconnected extends React.Component {

	static contextType = SchoolContext

	state = {
		subject: null,
	}

	fetchSubjects = async () => {

		showLoading();
		
		try {

			const params = { school_type: this.context.school.type }
			const res = await request.get('/api/general/subjects', { params });
			
			const subjects = res.data;
			actions.setSubjects(subjects);

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		if (!this.props.subjects)
			this.fetchSubjects();
	}

	render() {

		let ui;
		const refresh = <IconButton color='primary' onClick={this.fetchSubjects}>
			<RefreshIcon className='text-lg' />
		</IconButton>

		const P = props => <p className='text-sm text-gray-600'>
			{props.children}
		</p>

		if (!this.props.subjects) {
			ui = <div className='grid grid-cols-[1fr,auto]'>
				<P>Subjects not loaded</P>
				{refresh}
			</div>
		} else if (!this.props.subjects.length) {
			ui = <div className='grid grid-cols-[1fr,auto]'>
				<P>
					No subjects on the platform. Contact the developer to add subjects
				</P>
				{refresh}
			</div>
		} else {
			ui = <div className='grid grid-cols-[1fr,auto]'>
				<Autocomplete
					renderInput={
						props => <TextField
							{ ...props}
							label="Pick subject"
							size='small'
							variant='outlined'
						/>
					}
					options={
						this.props.subjects.map(subject => {
							const { _id, name } = subject;
							return { _id, name, label: name };
						})
					}
					onChange={
						(_, subject) => {
							this.setState({ subject })
						}
					}
					autoComplete
					blurOnSelect
					fullWidth
				/>
				<div>
					<IconButton 
						onClick={() => this.props.onSelect(this.state.subject)}
						color='primary'
					>
						<SendIcon />
					</IconButton>
					{refresh}
				</div>
			</div>
		}

		return <div>
			{ui}
		</div>;
	}
}

const mapStateToProps = state => {
	return {
		subjects: state.subjects
	}
}

const SubjectPicker = connect(mapStateToProps)(SubjectPickerUnconnected);
export default SubjectPicker;