
import isMobile from 'ismobilejs';
import actions from './redux/actions';


const sm = 600;
const md = 900;
const lg = 1200;
const xl = 1536;


function getScreenSize() {
   const height = window.innerHeight;
   const width = window.innerWidth;

   let size;

   if (width < sm) {
      size = "xs";
   } else if (width < md) {
      size = "sm"
   } else if (width < lg) {
      size = "md"
   } else if (width < xl) {
      size = "lg"
   } else {
      size = "xl"
   }


   const orientation = width > height ? "landscape" : "portrait"

   return {
      width,
      height,
      size,
      orientation
   }
}


function getDeviceInfo() {
   const deviceInfo = isMobile();
   const size = getScreenSize();
   const mobile = deviceInfo.phone || deviceInfo.tablet;

   let type;

   if (deviceInfo.tablet)
      type ="tablet"
   else if (deviceInfo.phone)
      type =  "phone";
   else
      type = "other";

   return {
      ...size,
      type,
      mobile,
   }
}

function updateDeviceInfo() {
   
   const deviceInfo = getDeviceInfo();
   actions.setDeviceInfo(deviceInfo);
   
}


function init() {
   window.removeEventListener("resize", updateDeviceInfo); // just in case this function is called more than once
   window.addEventListener("resize", updateDeviceInfo);
   updateDeviceInfo();
}

const device = {
   init,
   getDeviceInfo,
   getScreenSize,
}

export default device;