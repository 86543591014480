import { Component } from "react";
import PillLabel from '../../../components/PillLabel';
import request from "../../../request";
import swal from "sweetalert";
import { Button, CircularProgress } from "@mui/material";
import CenteredMessage from "../../../components/CenteredMessage";
import SubjectAttendance from "./SubjectAttendance";


export default class Attendance extends Component {

	state = {
		loading: false,
		attendance: null,
		showingSubjectAttendance: false
	}

	fetchAttendance = async () => {
		try {
			
			await this.updateState({ loading: false });

			const studentId = this.props.studentId || null;
			const res = await request.get(`/api/general/students/${studentId}/attendance`);
			const attendance = res.data;

			await this.updateState({ attendance })

		} catch (err) {
			swal(String(err));
		} finally {
			await this.updateState({ loading: false });
		}
	}

	componentDidMount() {
		this.fetchAttendance();
	}

	render() {

		let content;
		const { loading, attendance } = this.state;

		const circularProgressSize = 100;
		const circularProgressThickness = 4;

		if (loading) {
			content = <div className="py-[70px]">
				<div className="flex justify-center">
					<CircularProgress />
				</div>
			</div>
		} else if (!attendance) {
			content = <div className="h-[200px]">
				<CenteredMessage
					message={"Failed to fetch attendance data"}
					onRefresh={this.fetchAttendance}
				/>
			</div>
		} else {
			content = <>
				<div className="flex flex-col items-center">
					<div className="relative">
						<CircularProgress
							size={circularProgressSize}
							thickness={circularProgressThickness}
							variant="determinate"
							value={100}
							className="absolute top-0 left-0 [&_.MuiCircularProgress-circle]:text-blue-200"
						/>
						<CircularProgress
							size={circularProgressSize}
							thickness={circularProgressThickness}
							variant="determinate"
							value={attendance.average}
						/>
						<div className="absolute top-0 bottom-0 left-0 right-0 text-gray-600 flex items-center justify-center">
							{attendance.average}%
						</div>
					</div>
					<Button
						size="small"
						className="mt-2"
						onClick={() => this.setState({ showingSubjectAttendance: true })}
						disabled={!this.state.attendance?.class_subjects?.length}
						title="Show attendance per subject"
					>
						SUBJECT ATTENDANCE
					</Button>
				</div>
				{
					this.state.showingSubjectAttendance && <SubjectAttendance
						items={
							this.state.attendance.class_subjects.map(item => {
								const { subject: { name }, average } = item;
								return { name, average }
							})
						}
						close={() => this.setState({ showingSubjectAttendance: false })}
					/>
				}
			</>
		}

		return <div className="rounded border-[1px] border-gray-200 p-4">
			<PillLabel>ATTENDANCE</PillLabel>
			{content}
		</div>
	}
}